/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface About
 */
export interface About {
    /**
     * 
     * @type {string}
     * @memberof About
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof About
     */
    content?: string;
    /**
     * 
     * @type {Seo}
     * @memberof About
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof About
     */
    publishedAt?: string;
    /**
     * 
     * @type {Array<AboutGetInTouchLinks>}
     * @memberof About
     */
    getInTouchLinks?: Array<AboutGetInTouchLinks>;
    /**
     * 
     * @type {Array<Writer>}
     * @memberof About
     */
    authors?: Array<Writer>;
}
/**
 * 
 * @export
 * @interface AboutGetInTouchLinks
 */
export interface AboutGetInTouchLinks {
    /**
     * 
     * @type {string}
     * @memberof AboutGetInTouchLinks
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AboutGetInTouchLinks
     */
    url: string;
}
/**
 * 
 * @export
 * @interface AdPlacement
 */
export interface AdPlacement {
    /**
     * 
     * @type {string}
     * @memberof AdPlacement
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdPlacement
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdPlacement
     */
    html?: string;
    /**
     * 
     * @type {string}
     * @memberof AdPlacement
     */
    script?: string;
    /**
     * 
     * @type {string}
     * @memberof AdPlacement
     */
    placement: AdPlacementPlacementEnum;
    /**
     * 
     * @type {number}
     * @memberof AdPlacement
     */
    heightPc?: number;
    /**
     * 
     * @type {number}
     * @memberof AdPlacement
     */
    heightMobile?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AdPlacementPlacementEnum {
    HeaderBanner = 'header-banner',
    ClassicArticleHeaderBanner = 'classic-article-header-banner',
    ClassicArticleFooterBanner = 'classic-article-footer-banner',
    ArticleSideBanner = 'article-side-banner',
    ClassicArticleInContent1Paragraph = 'classic-article-in-content-1-paragraph',
    ClassicArticleInContent3Paragraph = 'classic-article-in-content-3-paragraph',
    HomepageDesktopVerticalBanner = 'homepage-desktop-vertical-banner',
    ClassicArticleInContent7Paragraph = 'classic-article-in-content-7-paragraph',
    ClassicArticleInContent10Paragraph = 'classic-article-in-content-10-paragraph',
    ClassicArticleInContent14Paragraph = 'classic-article-in-content-14-paragraph',
    ClassicArticleInContent19Paragraph = 'classic-article-in-content-19-paragraph',
    ClassicArticleInContent25Paragraph = 'classic-article-in-content-25-paragraph',
    ClassicArticleInContent32Paragraph = 'classic-article-in-content-32-paragraph',
    ClassicArticleInContent40Paragraph = 'classic-article-in-content-40-paragraph',
    ClassicArticleInContent49Paragraph = 'classic-article-in-content-49-paragraph'
}

/**
 * 
 * @export
 * @interface AdScript
 */
export interface AdScript {
    /**
     * 
     * @type {string}
     * @memberof AdScript
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdScript
     */
    place: AdScriptPlaceEnum;
    /**
     * 
     * @type {string}
     * @memberof AdScript
     */
    src?: string;
    /**
     * 
     * @type {string}
     * @memberof AdScript
     */
    script?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdScript
     */
    isActive?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum AdScriptPlaceEnum {
    Head = 'head',
    Body = 'body'
}

/**
 * 
 * @export
 * @interface AffiliateLinks
 */
export interface AffiliateLinks {
    /**
     * 
     * @type {string}
     * @memberof AffiliateLinks
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AffiliateLinks
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AffiliateLinks
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof AffiliateLinks
     */
    url: string;
    /**
     * 
     * @type {object}
     * @memberof AffiliateLinks
     */
    logo: object;
    /**
     * 
     * @type {string}
     * @memberof AffiliateLinks
     */
    publishedAt?: string;
}
/**
 * 
 * @export
 * @interface ApiCallPayload
 */
export interface ApiCallPayload {
    /**
     * 
     * @type {string}
     * @memberof ApiCallPayload
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCallPayload
     */
    api: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCallPayload
     */
    accessor: string;
}
/**
 * 
 * @export
 * @interface ApiCallResult
 */
export interface ApiCallResult {
    /**
     * 
     * @type {string}
     * @memberof ApiCallResult
     */
    value: string;
}
/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    pressRelease?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    slug: string;
    /**
     * 
     * @type {object}
     * @memberof Article
     */
    thumbnail: object;
    /**
     * 
     * @type {Seo}
     * @memberof Article
     */
    seo?: Seo;
    /**
     * 
     * @type {Writer}
     * @memberof Article
     */
    author?: Writer;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Article
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<Subcategory>}
     * @memberof Article
     */
    subcategories?: Array<Subcategory>;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    publishedDate?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Article
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {Array<Collection>}
     * @memberof Article
     */
    collections?: Array<Collection>;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof Article
     */
    assets?: Array<Asset>;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    featured?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    editorsPick?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    sponsored?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    readTimeInMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    articleAudioId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    publishAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    wasHot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    wasEditorsPick?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    publishedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    evergreen?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    updatedAt?: string;
    /**
     * 
     * @type {object}
     * @memberof Article
     */
    createdBy?: object;
    /**
     * 
     * @type {object}
     * @memberof Article
     */
    updatedBy?: object;
}
/**
 * 
 * @export
 * @interface ArticleFacets
 */
export interface ArticleFacets {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ArticleFacets
     */
    tagsName?: { [key: string]: number; };
    /**
     * 
     * @type {object}
     * @memberof ArticleFacets
     */
    categoriesSlug?: object;
    /**
     * 
     * @type {object}
     * @memberof ArticleFacets
     */
    subcategoriesSlug?: object;
}
/**
 * 
 * @export
 * @interface ArticleSearchResult
 */
export interface ArticleSearchResult {
    /**
     * 
     * @type {number}
     * @memberof ArticleSearchResult
     */
    nbHits?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleSearchResult
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleSearchResult
     */
    nbPages?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleSearchResult
     */
    hitsPerPage?: number;
    /**
     * 
     * @type {Array<Article>}
     * @memberof ArticleSearchResult
     */
    hits?: Array<Article>;
    /**
     * 
     * @type {ArticleFacets}
     * @memberof ArticleSearchResult
     */
    facets?: ArticleFacets;
}
/**
 * 
 * @export
 * @interface ArticleSearchResultAllOf
 */
export interface ArticleSearchResultAllOf {
    /**
     * 
     * @type {Array<Article>}
     * @memberof ArticleSearchResultAllOf
     */
    hits?: Array<Article>;
    /**
     * 
     * @type {ArticleFacets}
     * @memberof ArticleSearchResultAllOf
     */
    facets?: ArticleFacets;
}
/**
 * 
 * @export
 * @interface ArticleTemplate
 */
export interface ArticleTemplate {
    /**
     * 
     * @type {string}
     * @memberof ArticleTemplate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleTemplate
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleTemplate
     */
    sourceId: string;
}
/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    ticker: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    description?: string;
    /**
     * 
     * @type {object}
     * @memberof Asset
     */
    image?: object;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    marketCap?: number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    coingeckoImage?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    assetId: string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    priceUsd?: number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    priceChange24Hours?: number;
}
/**
 * 
 * @export
 * @interface AssetInfo
 */
export interface AssetInfo {
    /**
     * 
     * @type {string}
     * @memberof AssetInfo
     */
    symbol?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetInfo
     */
    name?: string;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof AssetInfo
     */
    prices?: Array<Array<number>>;
    /**
     * 
     * @type {AssetPrice}
     * @memberof AssetInfo
     */
    price?: AssetPrice;
    /**
     * 
     * @type {number}
     * @memberof AssetInfo
     */
    supply?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetInfo
     */
    rank?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetInfo
     */
    marketCap?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetInfo
     */
    volume24H?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetInfo
     */
    lastUpdated?: string;
}
/**
 * 
 * @export
 * @interface AssetPrice
 */
export interface AssetPrice {
    /**
     * 
     * @type {number}
     * @memberof AssetPrice
     */
    current?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetPrice
     */
    allTimeHigh?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetPrice
     */
    percentageChange?: number;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    slug: string;
    /**
     * 
     * @type {Array<Article>}
     * @memberof Category
     */
    articles?: Array<Article>;
    /**
     * 
     * @type {Array<Subcategory>}
     * @memberof Category
     */
    subcategories?: Array<Subcategory>;
    /**
     * 
     * @type {Seo}
     * @memberof Category
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    description: string;
    /**
     * 
     * @type {object}
     * @memberof Category
     */
    link?: object;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    publishedAt?: string;
}
/**
 * 
 * @export
 * @interface Collection
 */
export interface Collection {
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    name: string;
    /**
     * 
     * @type {Array<Article>}
     * @memberof Collection
     */
    articles?: Array<Article>;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    slug: string;
    /**
     * 
     * @type {object}
     * @memberof Collection
     */
    thumbnail: object;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    homepageDescription?: string;
    /**
     * 
     * @type {Seo}
     * @memberof Collection
     */
    seo?: Seo;
    /**
     * 
     * @type {Writer}
     * @memberof Collection
     */
    author?: Writer;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface CollectionSearchResult
 */
export interface CollectionSearchResult {
    /**
     * 
     * @type {number}
     * @memberof CollectionSearchResult
     */
    nbHits?: number;
    /**
     * 
     * @type {number}
     * @memberof CollectionSearchResult
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof CollectionSearchResult
     */
    nbPages?: number;
    /**
     * 
     * @type {number}
     * @memberof CollectionSearchResult
     */
    hitsPerPage?: number;
    /**
     * 
     * @type {Array<Collection>}
     * @memberof CollectionSearchResult
     */
    hits?: Array<Collection>;
}
/**
 * 
 * @export
 * @interface CollectionSearchResultAllOf
 */
export interface CollectionSearchResultAllOf {
    /**
     * 
     * @type {Array<Collection>}
     * @memberof CollectionSearchResultAllOf
     */
    hits?: Array<Collection>;
}
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    content?: string;
    /**
     * 
     * @type {Seo}
     * @memberof Contact
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    publishedAt?: string;
}
/**
 * 
 * @export
 * @interface DeletedLinks
 */
export interface DeletedLinks {
    /**
     * 
     * @type {string}
     * @memberof DeletedLinks
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeletedLinks
     */
    type: DeletedLinksTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DeletedLinks
     */
    status: DeletedLinksStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof DeletedLinks
     */
    itemId: number;
    /**
     * 
     * @type {string}
     * @memberof DeletedLinks
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof DeletedLinks
     */
    redirectTo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DeletedLinksTypeEnum {
    Article = 'article',
    Category = 'category',
    Subcategory = 'subcategory',
    Tag = 'tag',
    Writer = 'writer'
}
/**
    * @export
    * @enum {string}
    */
export enum DeletedLinksStatusEnum {
    Redirect = 'redirect',
    NotFound = 'not-found'
}

/**
 * 
 * @export
 * @interface Filters
 */
export interface Filters {
    /**
     * 
     * @type {Array<string>}
     * @memberof Filters
     */
    collection?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filters
     */
    article?: Array<string>;
}
/**
 * 
 * @export
 * @interface Footer
 */
export interface Footer {
    /**
     * 
     * @type {string}
     * @memberof Footer
     */
    id: string;
    /**
     * 
     * @type {Array<FooterSections>}
     * @memberof Footer
     */
    sections?: Array<FooterSections>;
    /**
     * 
     * @type {string}
     * @memberof Footer
     */
    publishedAt?: string;
}
/**
 * 
 * @export
 * @interface FooterLinks
 */
export interface FooterLinks {
    /**
     * 
     * @type {string}
     * @memberof FooterLinks
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FooterLinks
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FooterLinks
     */
    url: string;
}
/**
 * 
 * @export
 * @interface FooterSections
 */
export interface FooterSections {
    /**
     * 
     * @type {string}
     * @memberof FooterSections
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FooterSections
     */
    name: string;
    /**
     * 
     * @type {Array<FooterLinks>}
     * @memberof FooterSections
     */
    links?: Array<FooterLinks>;
}
/**
 * 
 * @export
 * @interface Global
 */
export interface Global {
    /**
     * 
     * @type {string}
     * @memberof Global
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Global
     */
    siteName: string;
    /**
     * 
     * @type {object}
     * @memberof Global
     */
    favicon: object;
    /**
     * 
     * @type {Seo}
     * @memberof Global
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof Global
     */
    metaTitlePrefix?: string;
    /**
     * 
     * @type {string}
     * @memberof Global
     */
    metaTitleSuffix?: string;
    /**
     * 
     * @type {Array<GlobalSliderAssets>}
     * @memberof Global
     */
    sliderAssets?: Array<GlobalSliderAssets>;
    /**
     * 
     * @type {string}
     * @memberof Global
     */
    publishedAt?: string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Global
     */
    navbarCategories?: Array<Category>;
    /**
     * 
     * @type {Array<GlobalLinks>}
     * @memberof Global
     */
    links?: Array<GlobalLinks>;
    /**
     * 
     * @type {string}
     * @memberof Global
     */
    affiliateLinksHeading?: string;
}
/**
 * 
 * @export
 * @interface GlobalAllData
 */
export interface GlobalAllData {
    /**
     * 
     * @type {Global}
     * @memberof GlobalAllData
     */
    global?: Global;
    /**
     * 
     * @type {Footer}
     * @memberof GlobalAllData
     */
    footer?: Footer;
}
/**
 * 
 * @export
 * @interface GlobalLinks
 */
export interface GlobalLinks {
    /**
     * 
     * @type {string}
     * @memberof GlobalLinks
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalLinks
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalLinks
     */
    url: string;
    /**
     * 
     * @type {Array<FooterLinks>}
     * @memberof GlobalLinks
     */
    sublinks?: Array<FooterLinks>;
}
/**
 * 
 * @export
 * @interface GlobalSliderAssets
 */
export interface GlobalSliderAssets {
    /**
     * 
     * @type {string}
     * @memberof GlobalSliderAssets
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSliderAssets
     */
    name: string;
    /**
     * 
     * @type {object}
     * @memberof GlobalSliderAssets
     */
    logo: object;
    /**
     * 
     * @type {string}
     * @memberof GlobalSliderAssets
     */
    displayName?: string;
    /**
     * 
     * @type {GlobalUrl}
     * @memberof GlobalSliderAssets
     */
    url?: GlobalUrl;
}
/**
 * 
 * @export
 * @interface GlobalUrl
 */
export interface GlobalUrl {
    /**
     * 
     * @type {string}
     * @memberof GlobalUrl
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalUrl
     */
    link: string;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalUrl
     */
    dofollow?: boolean;
}
/**
 * 
 * @export
 * @interface Homepage
 */
export interface Homepage {
    /**
     * 
     * @type {string}
     * @memberof Homepage
     */
    id: string;
    /**
     * 
     * @type {Seo}
     * @memberof Homepage
     */
    seo?: Seo;
    /**
     * 
     * @type {Array<Collection>}
     * @memberof Homepage
     */
    collections?: Array<Collection>;
    /**
     * 
     * @type {string}
     * @memberof Homepage
     */
    publishedAt?: string;
}
/**
 * 
 * @export
 * @interface HomepageAllData
 */
export interface HomepageAllData {
    /**
     * 
     * @type {Homepage}
     * @memberof HomepageAllData
     */
    homepage?: Homepage;
    /**
     * 
     * @type {Array<Article>}
     * @memberof HomepageAllData
     */
    featuredArticles?: Array<Article>;
    /**
     * 
     * @type {Array<Article>}
     * @memberof HomepageAllData
     */
    editorsPicksArticles?: Array<Article>;
    /**
     * 
     * @type {Array<Article>}
     * @memberof HomepageAllData
     */
    hotArticles?: Array<Article>;
    /**
     * 
     * @type {Array<TopSubcategory>}
     * @memberof HomepageAllData
     */
    topSubcategories?: Array<TopSubcategory>;
    /**
     * 
     * @type {Array<Article>}
     * @memberof HomepageAllData
     */
    pressReleases?: Array<Article>;
}
/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse400
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse403
 */
export interface InlineResponse403 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse403
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse403
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse403
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface Links
 */
export interface Links {
    /**
     * 
     * @type {string}
     * @memberof Links
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Links
     */
    contentType: LinksContentTypeEnum;
    /**
     * 
     * @type {Article}
     * @memberof Links
     */
    article?: Article;
    /**
     * 
     * @type {Category}
     * @memberof Links
     */
    category?: Category;
    /**
     * 
     * @type {Subcategory}
     * @memberof Links
     */
    subcategory?: Subcategory;
    /**
     * 
     * @type {string}
     * @memberof Links
     */
    slug: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LinksContentTypeEnum {
    Categories = 'categories',
    Subcategories = 'subcategories',
    Articles = 'articles'
}

/**
 * 
 * @export
 * @interface LogosPage
 */
export interface LogosPage {
    /**
     * 
     * @type {string}
     * @memberof LogosPage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LogosPage
     */
    sourceId?: string;
    /**
     * 
     * @type {string}
     * @memberof LogosPage
     */
    feedbackFormUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof LogosPage
     */
    pageTitle?: string;
    /**
     * 
     * @type {Text}
     * @memberof LogosPage
     */
    pageSubtitle?: Text;
    /**
     * 
     * @type {LogosPageSeo}
     * @memberof LogosPage
     */
    seo?: LogosPageSeo;
}
/**
 * 
 * @export
 * @interface LogosPageSeo
 */
export interface LogosPageSeo {
    /**
     * 
     * @type {string}
     * @memberof LogosPageSeo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LogosPageSeo
     */
    metaTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof LogosPageSeo
     */
    metaDescription: string;
    /**
     * 
     * @type {object}
     * @memberof LogosPageSeo
     */
    shareImage: object;
    /**
     * 
     * @type {string}
     * @memberof LogosPageSeo
     */
    shareTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof LogosPageSeo
     */
    shareDescription?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}
/**
 * 
 * @export
 * @interface NewAbout
 */
export interface NewAbout {
    /**
     * 
     * @type {string}
     * @memberof NewAbout
     */
    content?: string;
    /**
     * 
     * @type {Seo}
     * @memberof NewAbout
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof NewAbout
     */
    publishedAt?: string;
    /**
     * 
     * @type {Array<AboutGetInTouchLinks>}
     * @memberof NewAbout
     */
    getInTouchLinks?: Array<AboutGetInTouchLinks>;
    /**
     * 
     * @type {Array<Writer>}
     * @memberof NewAbout
     */
    authors?: Array<Writer>;
    /**
     * 
     * @type {string}
     * @memberof NewAbout
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAbout
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewAdPlacement
 */
export interface NewAdPlacement {
    /**
     * 
     * @type {boolean}
     * @memberof NewAdPlacement
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewAdPlacement
     */
    html?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAdPlacement
     */
    script?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAdPlacement
     */
    placement: NewAdPlacementPlacementEnum;
    /**
     * 
     * @type {number}
     * @memberof NewAdPlacement
     */
    heightPc?: number;
    /**
     * 
     * @type {number}
     * @memberof NewAdPlacement
     */
    heightMobile?: number;
    /**
     * 
     * @type {string}
     * @memberof NewAdPlacement
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAdPlacement
     */
    updatedBy?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NewAdPlacementPlacementEnum {
    HeaderBanner = 'header-banner',
    ClassicArticleHeaderBanner = 'classic-article-header-banner',
    ClassicArticleFooterBanner = 'classic-article-footer-banner',
    ArticleSideBanner = 'article-side-banner',
    ClassicArticleInContent1Paragraph = 'classic-article-in-content-1-paragraph',
    ClassicArticleInContent3Paragraph = 'classic-article-in-content-3-paragraph',
    HomepageDesktopVerticalBanner = 'homepage-desktop-vertical-banner',
    ClassicArticleInContent7Paragraph = 'classic-article-in-content-7-paragraph',
    ClassicArticleInContent10Paragraph = 'classic-article-in-content-10-paragraph',
    ClassicArticleInContent14Paragraph = 'classic-article-in-content-14-paragraph',
    ClassicArticleInContent19Paragraph = 'classic-article-in-content-19-paragraph',
    ClassicArticleInContent25Paragraph = 'classic-article-in-content-25-paragraph',
    ClassicArticleInContent32Paragraph = 'classic-article-in-content-32-paragraph',
    ClassicArticleInContent40Paragraph = 'classic-article-in-content-40-paragraph',
    ClassicArticleInContent49Paragraph = 'classic-article-in-content-49-paragraph'
}

/**
 * 
 * @export
 * @interface NewAdScript
 */
export interface NewAdScript {
    /**
     * 
     * @type {string}
     * @memberof NewAdScript
     */
    place: NewAdScriptPlaceEnum;
    /**
     * 
     * @type {string}
     * @memberof NewAdScript
     */
    src?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAdScript
     */
    script?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewAdScript
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewAdScript
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAdScript
     */
    updatedBy?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NewAdScriptPlaceEnum {
    Head = 'head',
    Body = 'body'
}

/**
 * 
 * @export
 * @interface NewAffiliateLinks
 */
export interface NewAffiliateLinks {
    /**
     * 
     * @type {string}
     * @memberof NewAffiliateLinks
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NewAffiliateLinks
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof NewAffiliateLinks
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof NewAffiliateLinks
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAffiliateLinks
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAffiliateLinks
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewArticle
 */
export interface NewArticle {
    /**
     * 
     * @type {string}
     * @memberof NewArticle
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NewArticle
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof NewArticle
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof NewArticle
     */
    slug?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewArticle
     */
    pressRelease?: boolean;
    /**
     * 
     * @type {Seo}
     * @memberof NewArticle
     */
    seo?: Seo;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewArticle
     */
    categories: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewArticle
     */
    subcategories?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewArticle
     */
    assets?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NewArticle
     */
    publishedDate?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewArticle
     */
    tags?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof NewArticle
     */
    featured?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewArticle
     */
    editorsPick?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewArticle
     */
    sponsored?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewArticle
     */
    hot?: boolean;
    /**
     * 
     * @type {object}
     * @memberof NewArticle
     */
    link?: object;
    /**
     * 
     * @type {number}
     * @memberof NewArticle
     */
    readTimeInMinutes: number;
    /**
     * 
     * @type {string}
     * @memberof NewArticle
     */
    publishAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewArticle
     */
    wasHot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewArticle
     */
    wasEditorsPick?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewArticle
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewArticle
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewArticle
     */
    updatedBy?: string;
    /**
     * 
     * @type {number}
     * @memberof NewArticle
     */
    views?: number;
}
/**
 * 
 * @export
 * @interface NewArticleTemplate
 */
export interface NewArticleTemplate {
    /**
     * 
     * @type {string}
     * @memberof NewArticleTemplate
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof NewArticleTemplate
     */
    sourceId: string;
    /**
     * 
     * @type {string}
     * @memberof NewArticleTemplate
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewArticleTemplate
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewAsset
 */
export interface NewAsset {
    /**
     * 
     * @type {string}
     * @memberof NewAsset
     */
    ticker: string;
    /**
     * 
     * @type {string}
     * @memberof NewAsset
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NewAsset
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof NewAsset
     */
    marketCap?: number;
    /**
     * 
     * @type {string}
     * @memberof NewAsset
     */
    coingeckoImage?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAsset
     */
    assetId: string;
    /**
     * 
     * @type {string}
     * @memberof NewAsset
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAsset
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewCategory
 */
export interface NewCategory {
    /**
     * 
     * @type {string}
     * @memberof NewCategory
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NewCategory
     */
    slug: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewCategory
     */
    articles?: Array<string>;
    /**
     * 
     * @type {Seo}
     * @memberof NewCategory
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof NewCategory
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCategory
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCategory
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCategory
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCategory
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewCollection
 */
export interface NewCollection {
    /**
     * 
     * @type {string}
     * @memberof NewCollection
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewCollection
     */
    articles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NewCollection
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCollection
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCollection
     */
    homepageDescription?: string;
    /**
     * 
     * @type {Seo}
     * @memberof NewCollection
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof NewCollection
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCollection
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCollection
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewContact
 */
export interface NewContact {
    /**
     * 
     * @type {string}
     * @memberof NewContact
     */
    content?: string;
    /**
     * 
     * @type {Seo}
     * @memberof NewContact
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof NewContact
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewContact
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewContact
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewDeletedLinks
 */
export interface NewDeletedLinks {
    /**
     * 
     * @type {string}
     * @memberof NewDeletedLinks
     */
    type: NewDeletedLinksTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NewDeletedLinks
     */
    status: NewDeletedLinksStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof NewDeletedLinks
     */
    itemId: number;
    /**
     * 
     * @type {string}
     * @memberof NewDeletedLinks
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof NewDeletedLinks
     */
    redirectTo?: string;
    /**
     * 
     * @type {string}
     * @memberof NewDeletedLinks
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewDeletedLinks
     */
    updatedBy?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NewDeletedLinksTypeEnum {
    Article = 'article',
    Category = 'category',
    Subcategory = 'subcategory',
    Tag = 'tag',
    Writer = 'writer'
}
/**
    * @export
    * @enum {string}
    */
export enum NewDeletedLinksStatusEnum {
    Redirect = 'redirect',
    NotFound = 'not-found'
}

/**
 * 
 * @export
 * @interface NewFooter
 */
export interface NewFooter {
    /**
     * 
     * @type {Array<FooterSections>}
     * @memberof NewFooter
     */
    sections?: Array<FooterSections>;
    /**
     * 
     * @type {string}
     * @memberof NewFooter
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFooter
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFooter
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewGlobal
 */
export interface NewGlobal {
    /**
     * 
     * @type {string}
     * @memberof NewGlobal
     */
    siteName?: string;
    /**
     * 
     * @type {Seo}
     * @memberof NewGlobal
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof NewGlobal
     */
    metaTitlePrefix?: string;
    /**
     * 
     * @type {string}
     * @memberof NewGlobal
     */
    metaTitleSuffix?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewGlobal
     */
    navbarCategories?: Array<string>;
    /**
     * 
     * @type {Array<GlobalLinks>}
     * @memberof NewGlobal
     */
    links?: Array<GlobalLinks>;
    /**
     * 
     * @type {Array<NewGlobalSliderAssets>}
     * @memberof NewGlobal
     */
    sliderAssets?: Array<NewGlobalSliderAssets>;
    /**
     * 
     * @type {string}
     * @memberof NewGlobal
     */
    affiliateLinksHeading?: string;
    /**
     * 
     * @type {string}
     * @memberof NewGlobal
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewGlobal
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewGlobal
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewGlobalSliderAssets
 */
export interface NewGlobalSliderAssets {
    /**
     * 
     * @type {string}
     * @memberof NewGlobalSliderAssets
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NewGlobalSliderAssets
     */
    name: string;
    /**
     * 
     * @type {object}
     * @memberof NewGlobalSliderAssets
     */
    logo?: object;
    /**
     * 
     * @type {string}
     * @memberof NewGlobalSliderAssets
     */
    displayName?: string;
    /**
     * 
     * @type {GlobalUrl}
     * @memberof NewGlobalSliderAssets
     */
    url?: GlobalUrl;
}
/**
 * 
 * @export
 * @interface NewHomepage
 */
export interface NewHomepage {
    /**
     * 
     * @type {Seo}
     * @memberof NewHomepage
     */
    seo?: Seo;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewHomepage
     */
    collections?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NewHomepage
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewHomepage
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewHomepage
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewLinks
 */
export interface NewLinks {
    /**
     * 
     * @type {string}
     * @memberof NewLinks
     */
    contentType: NewLinksContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NewLinks
     */
    article?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLinks
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLinks
     */
    subcategory?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLinks
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof NewLinks
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLinks
     */
    updatedBy?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NewLinksContentTypeEnum {
    Categories = 'categories',
    Subcategories = 'subcategories',
    Articles = 'articles'
}

/**
 * 
 * @export
 * @interface NewLogosPage
 */
export interface NewLogosPage {
    /**
     * 
     * @type {string}
     * @memberof NewLogosPage
     */
    sourceId?: string;
    /**
     * 
     * @type {LogosPageSeo}
     * @memberof NewLogosPage
     */
    seo?: LogosPageSeo;
    /**
     * 
     * @type {string}
     * @memberof NewLogosPage
     */
    feedbackFormUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLogosPage
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLogosPage
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewPr
 */
export interface NewPr {
    /**
     * 
     * @type {string}
     * @memberof NewPr
     */
    content?: string;
    /**
     * 
     * @type {LogosPageSeo}
     * @memberof NewPr
     */
    seo?: LogosPageSeo;
    /**
     * 
     * @type {string}
     * @memberof NewPr
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPr
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPr
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewPrivacyPolicy
 */
export interface NewPrivacyPolicy {
    /**
     * 
     * @type {string}
     * @memberof NewPrivacyPolicy
     */
    content?: string;
    /**
     * 
     * @type {Seo}
     * @memberof NewPrivacyPolicy
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof NewPrivacyPolicy
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPrivacyPolicy
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPrivacyPolicy
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewSearch
 */
export interface NewSearch {
    /**
     * 
     * @type {string}
     * @memberof NewSearch
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSearch
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewSubcategory
 */
export interface NewSubcategory {
    /**
     * 
     * @type {string}
     * @memberof NewSubcategory
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSubcategory
     */
    slug?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewSubcategory
     */
    articles?: Array<string>;
    /**
     * 
     * @type {Seo}
     * @memberof NewSubcategory
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof NewSubcategory
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSubcategory
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSubcategory
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSubcategory
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewTag
 */
export interface NewTag {
    /**
     * 
     * @type {string}
     * @memberof NewTag
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NewTag
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof NewTag
     */
    description: string;
    /**
     * 
     * @type {Seo}
     * @memberof NewTag
     */
    seo?: Seo;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewTag
     */
    articles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NewTag
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTag
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTag
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewTermsOfUse
 */
export interface NewTermsOfUse {
    /**
     * 
     * @type {string}
     * @memberof NewTermsOfUse
     */
    content?: string;
    /**
     * 
     * @type {Seo}
     * @memberof NewTermsOfUse
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof NewTermsOfUse
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTermsOfUse
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTermsOfUse
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface NewWriter
 */
export interface NewWriter {
    /**
     * 
     * @type {string}
     * @memberof NewWriter
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NewWriter
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof NewWriter
     */
    contactEmail?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewWriter
     */
    articles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NewWriter
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWriter
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWriter
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWriter
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWriter
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWriter
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface Pages
 */
export interface Pages {
    /**
     * 
     * @type {number}
     * @memberof Pages
     */
    collection?: number;
    /**
     * 
     * @type {number}
     * @memberof Pages
     */
    article?: number;
}
/**
 * 
 * @export
 * @interface Paths
 */
export interface Paths {
    /**
     * 
     * @type {Array<any>}
     * @memberof Paths
     */
    article?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof Paths
     */
    category?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof Paths
     */
    subcategory?: Array<any>;
}
/**
 * 
 * @export
 * @interface Pr
 */
export interface Pr {
    /**
     * 
     * @type {string}
     * @memberof Pr
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Pr
     */
    content?: string;
    /**
     * 
     * @type {LogosPageSeo}
     * @memberof Pr
     */
    seo?: LogosPageSeo;
    /**
     * 
     * @type {string}
     * @memberof Pr
     */
    publishedAt?: string;
}
/**
 * 
 * @export
 * @interface PressReleaseBody
 */
export interface PressReleaseBody {
    /**
     * 
     * @type {string}
     * @memberof PressReleaseBody
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof PressReleaseBody
     */
    author?: number;
    /**
     * 
     * @type {string}
     * @memberof PressReleaseBody
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PressReleaseBody
     */
    publishedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PressReleaseBody
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PressReleaseBody
     */
    html?: string;
}
/**
 * 
 * @export
 * @interface PrivacyPolicy
 */
export interface PrivacyPolicy {
    /**
     * 
     * @type {string}
     * @memberof PrivacyPolicy
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PrivacyPolicy
     */
    content?: string;
    /**
     * 
     * @type {Seo}
     * @memberof PrivacyPolicy
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof PrivacyPolicy
     */
    publishedAt?: string;
}
/**
 * 
 * @export
 * @interface RelatedArticle
 */
export interface RelatedArticle {
    /**
     * 
     * @type {string}
     * @memberof RelatedArticle
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedArticle
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedArticle
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelatedArticle
     */
    sponsored?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelatedArticle
     */
    pressRelease?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelatedArticle
     */
    readTimeInMinutes?: number;
    /**
     * 
     * @type {string}
     * @memberof RelatedArticle
     */
    publishAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelatedArticle
     */
    wasHot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelatedArticle
     */
    wasEditorsPick?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelatedArticle
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedArticle
     */
    slug?: string;
    /**
     * 
     * @type {object}
     * @memberof RelatedArticle
     */
    thumbnail?: object;
    /**
     * 
     * @type {Writer}
     * @memberof RelatedArticle
     */
    author?: Writer;
    /**
     * 
     * @type {Array<Category>}
     * @memberof RelatedArticle
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<Subcategory>}
     * @memberof RelatedArticle
     */
    subcategories?: Array<Subcategory>;
    /**
     * 
     * @type {string}
     * @memberof RelatedArticle
     */
    publishedDate?: string;
}
/**
 * 
 * @export
 * @interface Search
 */
export interface Search {
    /**
     * 
     * @type {SearchResults}
     * @memberof Search
     */
    searchResults?: SearchResults;
}
/**
 * 
 * @export
 * @interface SearchFilters
 */
export interface SearchFilters {
    /**
     * 
     * @type {Array<SearchFiltersType & Tag>}
     * @memberof SearchFilters
     */
    tags?: Array<SearchFiltersType & Tag>;
    /**
     * 
     * @type {Array<SearchFiltersType & Subcategory>}
     * @memberof SearchFilters
     */
    subcategories?: Array<SearchFiltersType & Subcategory>;
    /**
     * 
     * @type {Array<SearchFiltersType & Category>}
     * @memberof SearchFilters
     */
    categories?: Array<SearchFiltersType & Category>;
}
/**
 * 
 * @export
 * @interface SearchFiltersType
 */
export interface SearchFiltersType {
    /**
     * 
     * @type {string}
     * @memberof SearchFiltersType
     */
    type?: SearchFiltersTypeTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchFiltersTypeTypeEnum {
    Tag = 'tag',
    Subcategory = 'subcategory',
    Category = 'category'
}

/**
 * 
 * @export
 * @interface SearchResultSingle
 */
export interface SearchResultSingle {
    /**
     * 
     * @type {number}
     * @memberof SearchResultSingle
     */
    nbHits?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResultSingle
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResultSingle
     */
    nbPages?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResultSingle
     */
    hitsPerPage?: number;
}
/**
 * 
 * @export
 * @interface SearchResults
 */
export interface SearchResults {
    /**
     * 
     * @type {ArticleSearchResult}
     * @memberof SearchResults
     */
    article?: ArticleSearchResult;
    /**
     * 
     * @type {CollectionSearchResult}
     * @memberof SearchResults
     */
    collection?: CollectionSearchResult;
}
/**
 * 
 * @export
 * @interface Seo
 */
export interface Seo {
    /**
     * 
     * @type {string}
     * @memberof Seo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Seo
     */
    metaTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Seo
     */
    metaDescription?: string;
    /**
     * 
     * @type {object}
     * @memberof Seo
     */
    shareImage?: object;
    /**
     * 
     * @type {string}
     * @memberof Seo
     */
    shareTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Seo
     */
    shareDescription?: string;
}
/**
 * 
 * @export
 * @interface Subcategory
 */
export interface Subcategory {
    /**
     * 
     * @type {string}
     * @memberof Subcategory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Subcategory
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Subcategory
     */
    slug: string;
    /**
     * 
     * @type {Array<Article>}
     * @memberof Subcategory
     */
    articles?: Array<Article>;
    /**
     * 
     * @type {Seo}
     * @memberof Subcategory
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof Subcategory
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Subcategory
     */
    publishedAt?: string;
    /**
     * 
     * @type {object}
     * @memberof Subcategory
     */
    link?: object;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    description: string;
    /**
     * 
     * @type {Seo}
     * @memberof Tag
     */
    seo?: Seo;
    /**
     * 
     * @type {Array<Article>}
     * @memberof Tag
     */
    articles?: Array<Article>;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    publishedAt?: string;
}
/**
 * 
 * @export
 * @interface TermsOfUse
 */
export interface TermsOfUse {
    /**
     * 
     * @type {string}
     * @memberof TermsOfUse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TermsOfUse
     */
    content?: string;
    /**
     * 
     * @type {Seo}
     * @memberof TermsOfUse
     */
    seo?: Seo;
    /**
     * 
     * @type {string}
     * @memberof TermsOfUse
     */
    publishedAt?: string;
}
/**
 * 
 * @export
 * @interface TopSubcategory
 */
export interface TopSubcategory {
    /**
     * 
     * @type {Subcategory}
     * @memberof TopSubcategory
     */
    subcategory?: Subcategory;
    /**
     * 
     * @type {Array<Article>}
     * @memberof TopSubcategory
     */
    articles?: Array<Article>;
}
/**
 * 
 * @export
 * @interface Url
 */
export interface Url {
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    loc?: string;
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    lastmod?: string;
}
/**
 * 
 * @export
 * @interface Urlset
 */
export interface Urlset {
    /**
     * 
     * @type {string}
     * @memberof Urlset
     */
    xmlns?: string;
    /**
     * 
     * @type {Array<Url>}
     * @memberof Urlset
     */
    urls?: Array<Url>;
}
/**
 * 
 * @export
 * @interface Writer
 */
export interface Writer {
    /**
     * 
     * @type {string}
     * @memberof Writer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Writer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Writer
     */
    slug: string;
    /**
     * 
     * @type {object}
     * @memberof Writer
     */
    avatar?: object;
    /**
     * 
     * @type {string}
     * @memberof Writer
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Writer
     */
    linkedin?: string;
    /**
     * 
     * @type {string}
     * @memberof Writer
     */
    twitter?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof Writer
     */
    articles?: Array<object>;
    /**
     * 
     * @type {object}
     * @memberof Writer
     */
    user?: object;
    /**
     * 
     * @type {string}
     * @memberof Writer
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof Writer
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Writer
     */
    publishedAt?: string;
}

/**
 * AboutApi - axios parameter creator
 * @export
 */
export const AboutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a single about record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/about`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all the about\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/about`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a single about record
         * @param {NewAbout} newAbout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutPut: async (newAbout: NewAbout, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newAbout' is not null or undefined
            assertParamExists('aboutPut', 'newAbout', newAbout)
            const localVarPath = `/about`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAbout, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AboutApi - functional programming interface
 * @export
 */
export const AboutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AboutApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a single about record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all the about\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<About>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a single about record
         * @param {NewAbout} newAbout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutPut(newAbout: NewAbout, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<About>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutPut(newAbout, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AboutApi - factory interface
 * @export
 */
export const AboutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AboutApiFp(configuration)
    return {
        /**
         * Delete a single about record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutDelete(options?: any): AxiosPromise<number> {
            return localVarFp.aboutDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Find all the about\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<About> {
            return localVarFp.aboutGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a single about record
         * @param {NewAbout} newAbout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutPut(newAbout: NewAbout, options?: any): AxiosPromise<About> {
            return localVarFp.aboutPut(newAbout, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aboutGet operation in AboutApi.
 * @export
 * @interface AboutApiAboutGetRequest
 */
export interface AboutApiAboutGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof AboutApiAboutGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof AboutApiAboutGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof AboutApiAboutGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof AboutApiAboutGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof AboutApiAboutGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof AboutApiAboutGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof AboutApiAboutGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof AboutApiAboutGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof AboutApiAboutGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof AboutApiAboutGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof AboutApiAboutGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof AboutApiAboutGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for aboutPut operation in AboutApi.
 * @export
 * @interface AboutApiAboutPutRequest
 */
export interface AboutApiAboutPutRequest {
    /**
     * 
     * @type {NewAbout}
     * @memberof AboutApiAboutPut
     */
    readonly newAbout: NewAbout
}

/**
 * AboutApi - object-oriented interface
 * @export
 * @class AboutApi
 * @extends {BaseAPI}
 */
export class AboutApi extends BaseAPI {
    /**
     * Delete a single about record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AboutApi
     */
    public aboutDelete(options?: any) {
        return AboutApiFp(this.configuration).aboutDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all the about\'s records
     * @param {AboutApiAboutGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AboutApi
     */
    public aboutGet(requestParameters: AboutApiAboutGetRequest = {}, options?: any) {
        return AboutApiFp(this.configuration).aboutGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a single about record
     * @param {AboutApiAboutPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AboutApi
     */
    public aboutPut(requestParameters: AboutApiAboutPutRequest, options?: any) {
        return AboutApiFp(this.configuration).aboutPut(requestParameters.newAbout, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdPlacementApi - axios parameter creator
 * @export
 */
export const AdPlacementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adPlacementsCountGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ad-placements/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adPlacementsGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ad-placements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adPlacementsIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adPlacementsIdDelete', 'id', id)
            const localVarPath = `/ad-placements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adPlacementsIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adPlacementsIdGet', 'id', id)
            const localVarPath = `/ad-placements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewAdPlacement} newAdPlacement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adPlacementsIdPut: async (id: string, newAdPlacement: NewAdPlacement, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adPlacementsIdPut', 'id', id)
            // verify required parameter 'newAdPlacement' is not null or undefined
            assertParamExists('adPlacementsIdPut', 'newAdPlacement', newAdPlacement)
            const localVarPath = `/ad-placements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAdPlacement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new record
         * @param {NewAdPlacement} newAdPlacement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adPlacementsPost: async (newAdPlacement: NewAdPlacement, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newAdPlacement' is not null or undefined
            assertParamExists('adPlacementsPost', 'newAdPlacement', newAdPlacement)
            const localVarPath = `/ad-placements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAdPlacement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdPlacementApi - functional programming interface
 * @export
 */
export const AdPlacementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdPlacementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adPlacementsCountGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adPlacementsCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adPlacementsGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdPlacement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adPlacementsGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adPlacementsIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adPlacementsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adPlacementsIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adPlacementsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewAdPlacement} newAdPlacement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adPlacementsIdPut(id: string, newAdPlacement: NewAdPlacement, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adPlacementsIdPut(id, newAdPlacement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new record
         * @param {NewAdPlacement} newAdPlacement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adPlacementsPost(newAdPlacement: NewAdPlacement, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adPlacementsPost(newAdPlacement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdPlacementApi - factory interface
 * @export
 */
export const AdPlacementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdPlacementApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adPlacementsCountGet(options?: any): AxiosPromise<object> {
            return localVarFp.adPlacementsCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adPlacementsGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<AdPlacement>> {
            return localVarFp.adPlacementsGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adPlacementsIdDelete(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.adPlacementsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adPlacementsIdGet(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.adPlacementsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewAdPlacement} newAdPlacement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adPlacementsIdPut(id: string, newAdPlacement: NewAdPlacement, options?: any): AxiosPromise<object> {
            return localVarFp.adPlacementsIdPut(id, newAdPlacement, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new record
         * @param {NewAdPlacement} newAdPlacement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adPlacementsPost(newAdPlacement: NewAdPlacement, options?: any): AxiosPromise<object> {
            return localVarFp.adPlacementsPost(newAdPlacement, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adPlacementsGet operation in AdPlacementApi.
 * @export
 * @interface AdPlacementApiAdPlacementsGetRequest
 */
export interface AdPlacementApiAdPlacementsGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof AdPlacementApiAdPlacementsGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof AdPlacementApiAdPlacementsGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof AdPlacementApiAdPlacementsGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof AdPlacementApiAdPlacementsGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof AdPlacementApiAdPlacementsGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof AdPlacementApiAdPlacementsGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof AdPlacementApiAdPlacementsGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof AdPlacementApiAdPlacementsGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof AdPlacementApiAdPlacementsGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof AdPlacementApiAdPlacementsGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof AdPlacementApiAdPlacementsGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof AdPlacementApiAdPlacementsGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for adPlacementsIdDelete operation in AdPlacementApi.
 * @export
 * @interface AdPlacementApiAdPlacementsIdDeleteRequest
 */
export interface AdPlacementApiAdPlacementsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdPlacementApiAdPlacementsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for adPlacementsIdGet operation in AdPlacementApi.
 * @export
 * @interface AdPlacementApiAdPlacementsIdGetRequest
 */
export interface AdPlacementApiAdPlacementsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdPlacementApiAdPlacementsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for adPlacementsIdPut operation in AdPlacementApi.
 * @export
 * @interface AdPlacementApiAdPlacementsIdPutRequest
 */
export interface AdPlacementApiAdPlacementsIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdPlacementApiAdPlacementsIdPut
     */
    readonly id: string

    /**
     * 
     * @type {NewAdPlacement}
     * @memberof AdPlacementApiAdPlacementsIdPut
     */
    readonly newAdPlacement: NewAdPlacement
}

/**
 * Request parameters for adPlacementsPost operation in AdPlacementApi.
 * @export
 * @interface AdPlacementApiAdPlacementsPostRequest
 */
export interface AdPlacementApiAdPlacementsPostRequest {
    /**
     * 
     * @type {NewAdPlacement}
     * @memberof AdPlacementApiAdPlacementsPost
     */
    readonly newAdPlacement: NewAdPlacement
}

/**
 * AdPlacementApi - object-oriented interface
 * @export
 * @class AdPlacementApi
 * @extends {BaseAPI}
 */
export class AdPlacementApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdPlacementApi
     */
    public adPlacementsCountGet(options?: any) {
        return AdPlacementApiFp(this.configuration).adPlacementsCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdPlacementApiAdPlacementsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdPlacementApi
     */
    public adPlacementsGet(requestParameters: AdPlacementApiAdPlacementsGetRequest = {}, options?: any) {
        return AdPlacementApiFp(this.configuration).adPlacementsGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a record
     * @param {AdPlacementApiAdPlacementsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdPlacementApi
     */
    public adPlacementsIdDelete(requestParameters: AdPlacementApiAdPlacementsIdDeleteRequest, options?: any) {
        return AdPlacementApiFp(this.configuration).adPlacementsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdPlacementApiAdPlacementsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdPlacementApi
     */
    public adPlacementsIdGet(requestParameters: AdPlacementApiAdPlacementsIdGetRequest, options?: any) {
        return AdPlacementApiFp(this.configuration).adPlacementsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a record
     * @param {AdPlacementApiAdPlacementsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdPlacementApi
     */
    public adPlacementsIdPut(requestParameters: AdPlacementApiAdPlacementsIdPutRequest, options?: any) {
        return AdPlacementApiFp(this.configuration).adPlacementsIdPut(requestParameters.id, requestParameters.newAdPlacement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new record
     * @param {AdPlacementApiAdPlacementsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdPlacementApi
     */
    public adPlacementsPost(requestParameters: AdPlacementApiAdPlacementsPostRequest, options?: any) {
        return AdPlacementApiFp(this.configuration).adPlacementsPost(requestParameters.newAdPlacement, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdScriptApi - axios parameter creator
 * @export
 */
export const AdScriptApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adScriptsCountGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ad-scripts/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adScriptsGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ad-scripts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adScriptsIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adScriptsIdDelete', 'id', id)
            const localVarPath = `/ad-scripts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adScriptsIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adScriptsIdGet', 'id', id)
            const localVarPath = `/ad-scripts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewAdScript} newAdScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adScriptsIdPut: async (id: string, newAdScript: NewAdScript, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adScriptsIdPut', 'id', id)
            // verify required parameter 'newAdScript' is not null or undefined
            assertParamExists('adScriptsIdPut', 'newAdScript', newAdScript)
            const localVarPath = `/ad-scripts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAdScript, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new record
         * @param {NewAdScript} newAdScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adScriptsPost: async (newAdScript: NewAdScript, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newAdScript' is not null or undefined
            assertParamExists('adScriptsPost', 'newAdScript', newAdScript)
            const localVarPath = `/ad-scripts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAdScript, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdScriptApi - functional programming interface
 * @export
 */
export const AdScriptApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdScriptApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adScriptsCountGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adScriptsCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adScriptsGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdScript>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adScriptsGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adScriptsIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adScriptsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adScriptsIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adScriptsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewAdScript} newAdScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adScriptsIdPut(id: string, newAdScript: NewAdScript, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adScriptsIdPut(id, newAdScript, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new record
         * @param {NewAdScript} newAdScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adScriptsPost(newAdScript: NewAdScript, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adScriptsPost(newAdScript, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdScriptApi - factory interface
 * @export
 */
export const AdScriptApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdScriptApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adScriptsCountGet(options?: any): AxiosPromise<number> {
            return localVarFp.adScriptsCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adScriptsGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<AdScript>> {
            return localVarFp.adScriptsGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adScriptsIdDelete(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.adScriptsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adScriptsIdGet(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.adScriptsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewAdScript} newAdScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adScriptsIdPut(id: string, newAdScript: NewAdScript, options?: any): AxiosPromise<object> {
            return localVarFp.adScriptsIdPut(id, newAdScript, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new record
         * @param {NewAdScript} newAdScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adScriptsPost(newAdScript: NewAdScript, options?: any): AxiosPromise<object> {
            return localVarFp.adScriptsPost(newAdScript, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adScriptsGet operation in AdScriptApi.
 * @export
 * @interface AdScriptApiAdScriptsGetRequest
 */
export interface AdScriptApiAdScriptsGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof AdScriptApiAdScriptsGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof AdScriptApiAdScriptsGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof AdScriptApiAdScriptsGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof AdScriptApiAdScriptsGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof AdScriptApiAdScriptsGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof AdScriptApiAdScriptsGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof AdScriptApiAdScriptsGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof AdScriptApiAdScriptsGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof AdScriptApiAdScriptsGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof AdScriptApiAdScriptsGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof AdScriptApiAdScriptsGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof AdScriptApiAdScriptsGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for adScriptsIdDelete operation in AdScriptApi.
 * @export
 * @interface AdScriptApiAdScriptsIdDeleteRequest
 */
export interface AdScriptApiAdScriptsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdScriptApiAdScriptsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for adScriptsIdGet operation in AdScriptApi.
 * @export
 * @interface AdScriptApiAdScriptsIdGetRequest
 */
export interface AdScriptApiAdScriptsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdScriptApiAdScriptsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for adScriptsIdPut operation in AdScriptApi.
 * @export
 * @interface AdScriptApiAdScriptsIdPutRequest
 */
export interface AdScriptApiAdScriptsIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdScriptApiAdScriptsIdPut
     */
    readonly id: string

    /**
     * 
     * @type {NewAdScript}
     * @memberof AdScriptApiAdScriptsIdPut
     */
    readonly newAdScript: NewAdScript
}

/**
 * Request parameters for adScriptsPost operation in AdScriptApi.
 * @export
 * @interface AdScriptApiAdScriptsPostRequest
 */
export interface AdScriptApiAdScriptsPostRequest {
    /**
     * 
     * @type {NewAdScript}
     * @memberof AdScriptApiAdScriptsPost
     */
    readonly newAdScript: NewAdScript
}

/**
 * AdScriptApi - object-oriented interface
 * @export
 * @class AdScriptApi
 * @extends {BaseAPI}
 */
export class AdScriptApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdScriptApi
     */
    public adScriptsCountGet(options?: any) {
        return AdScriptApiFp(this.configuration).adScriptsCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdScriptApiAdScriptsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdScriptApi
     */
    public adScriptsGet(requestParameters: AdScriptApiAdScriptsGetRequest = {}, options?: any) {
        return AdScriptApiFp(this.configuration).adScriptsGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a record
     * @param {AdScriptApiAdScriptsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdScriptApi
     */
    public adScriptsIdDelete(requestParameters: AdScriptApiAdScriptsIdDeleteRequest, options?: any) {
        return AdScriptApiFp(this.configuration).adScriptsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdScriptApiAdScriptsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdScriptApi
     */
    public adScriptsIdGet(requestParameters: AdScriptApiAdScriptsIdGetRequest, options?: any) {
        return AdScriptApiFp(this.configuration).adScriptsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a record
     * @param {AdScriptApiAdScriptsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdScriptApi
     */
    public adScriptsIdPut(requestParameters: AdScriptApiAdScriptsIdPutRequest, options?: any) {
        return AdScriptApiFp(this.configuration).adScriptsIdPut(requestParameters.id, requestParameters.newAdScript, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new record
     * @param {AdScriptApiAdScriptsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdScriptApi
     */
    public adScriptsPost(requestParameters: AdScriptApiAdScriptsPostRequest, options?: any) {
        return AdScriptApiFp(this.configuration).adScriptsPost(requestParameters.newAdScript, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AffiliateLinksApi - axios parameter creator
 * @export
 */
export const AffiliateLinksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the numver of affiliate-links documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateLinksCountGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/affiliate-links/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all the affiliate-links\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateLinksGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/affiliate-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a single affiliate-links record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateLinksIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('affiliateLinksIdDelete', 'id', id)
            const localVarPath = `/affiliate-links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find one affiliate-links record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateLinksIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('affiliateLinksIdGet', 'id', id)
            const localVarPath = `/affiliate-links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a single affiliate-links record
         * @param {string} id 
         * @param {NewAffiliateLinks} newAffiliateLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateLinksIdPut: async (id: string, newAffiliateLinks: NewAffiliateLinks, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('affiliateLinksIdPut', 'id', id)
            // verify required parameter 'newAffiliateLinks' is not null or undefined
            assertParamExists('affiliateLinksIdPut', 'newAffiliateLinks', newAffiliateLinks)
            const localVarPath = `/affiliate-links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAffiliateLinks, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new affiliate-links record
         * @param {NewAffiliateLinks} newAffiliateLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateLinksPost: async (newAffiliateLinks: NewAffiliateLinks, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newAffiliateLinks' is not null or undefined
            assertParamExists('affiliateLinksPost', 'newAffiliateLinks', newAffiliateLinks)
            const localVarPath = `/affiliate-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAffiliateLinks, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AffiliateLinksApi - functional programming interface
 * @export
 */
export const AffiliateLinksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AffiliateLinksApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the numver of affiliate-links documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliateLinksCountGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLinksCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all the affiliate-links\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliateLinksGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AffiliateLinks>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLinksGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a single affiliate-links record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliateLinksIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLinksIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find one affiliate-links record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliateLinksIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLinksIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a single affiliate-links record
         * @param {string} id 
         * @param {NewAffiliateLinks} newAffiliateLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliateLinksIdPut(id: string, newAffiliateLinks: NewAffiliateLinks, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLinksIdPut(id, newAffiliateLinks, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new affiliate-links record
         * @param {NewAffiliateLinks} newAffiliateLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliateLinksPost(newAffiliateLinks: NewAffiliateLinks, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLinksPost(newAffiliateLinks, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AffiliateLinksApi - factory interface
 * @export
 */
export const AffiliateLinksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AffiliateLinksApiFp(configuration)
    return {
        /**
         * Retrieve the numver of affiliate-links documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateLinksCountGet(options?: any): AxiosPromise<object> {
            return localVarFp.affiliateLinksCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Find all the affiliate-links\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateLinksGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<AffiliateLinks>> {
            return localVarFp.affiliateLinksGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a single affiliate-links record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateLinksIdDelete(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.affiliateLinksIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Find one affiliate-links record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateLinksIdGet(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.affiliateLinksIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a single affiliate-links record
         * @param {string} id 
         * @param {NewAffiliateLinks} newAffiliateLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateLinksIdPut(id: string, newAffiliateLinks: NewAffiliateLinks, options?: any): AxiosPromise<object> {
            return localVarFp.affiliateLinksIdPut(id, newAffiliateLinks, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new affiliate-links record
         * @param {NewAffiliateLinks} newAffiliateLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateLinksPost(newAffiliateLinks: NewAffiliateLinks, options?: any): AxiosPromise<object> {
            return localVarFp.affiliateLinksPost(newAffiliateLinks, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for affiliateLinksGet operation in AffiliateLinksApi.
 * @export
 * @interface AffiliateLinksApiAffiliateLinksGetRequest
 */
export interface AffiliateLinksApiAffiliateLinksGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof AffiliateLinksApiAffiliateLinksGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof AffiliateLinksApiAffiliateLinksGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof AffiliateLinksApiAffiliateLinksGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof AffiliateLinksApiAffiliateLinksGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof AffiliateLinksApiAffiliateLinksGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof AffiliateLinksApiAffiliateLinksGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof AffiliateLinksApiAffiliateLinksGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof AffiliateLinksApiAffiliateLinksGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof AffiliateLinksApiAffiliateLinksGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof AffiliateLinksApiAffiliateLinksGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof AffiliateLinksApiAffiliateLinksGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof AffiliateLinksApiAffiliateLinksGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for affiliateLinksIdDelete operation in AffiliateLinksApi.
 * @export
 * @interface AffiliateLinksApiAffiliateLinksIdDeleteRequest
 */
export interface AffiliateLinksApiAffiliateLinksIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AffiliateLinksApiAffiliateLinksIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for affiliateLinksIdGet operation in AffiliateLinksApi.
 * @export
 * @interface AffiliateLinksApiAffiliateLinksIdGetRequest
 */
export interface AffiliateLinksApiAffiliateLinksIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AffiliateLinksApiAffiliateLinksIdGet
     */
    readonly id: string
}

/**
 * Request parameters for affiliateLinksIdPut operation in AffiliateLinksApi.
 * @export
 * @interface AffiliateLinksApiAffiliateLinksIdPutRequest
 */
export interface AffiliateLinksApiAffiliateLinksIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AffiliateLinksApiAffiliateLinksIdPut
     */
    readonly id: string

    /**
     * 
     * @type {NewAffiliateLinks}
     * @memberof AffiliateLinksApiAffiliateLinksIdPut
     */
    readonly newAffiliateLinks: NewAffiliateLinks
}

/**
 * Request parameters for affiliateLinksPost operation in AffiliateLinksApi.
 * @export
 * @interface AffiliateLinksApiAffiliateLinksPostRequest
 */
export interface AffiliateLinksApiAffiliateLinksPostRequest {
    /**
     * 
     * @type {NewAffiliateLinks}
     * @memberof AffiliateLinksApiAffiliateLinksPost
     */
    readonly newAffiliateLinks: NewAffiliateLinks
}

/**
 * AffiliateLinksApi - object-oriented interface
 * @export
 * @class AffiliateLinksApi
 * @extends {BaseAPI}
 */
export class AffiliateLinksApi extends BaseAPI {
    /**
     * Retrieve the numver of affiliate-links documents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliateLinksApi
     */
    public affiliateLinksCountGet(options?: any) {
        return AffiliateLinksApiFp(this.configuration).affiliateLinksCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all the affiliate-links\'s records
     * @param {AffiliateLinksApiAffiliateLinksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliateLinksApi
     */
    public affiliateLinksGet(requestParameters: AffiliateLinksApiAffiliateLinksGetRequest = {}, options?: any) {
        return AffiliateLinksApiFp(this.configuration).affiliateLinksGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a single affiliate-links record
     * @param {AffiliateLinksApiAffiliateLinksIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliateLinksApi
     */
    public affiliateLinksIdDelete(requestParameters: AffiliateLinksApiAffiliateLinksIdDeleteRequest, options?: any) {
        return AffiliateLinksApiFp(this.configuration).affiliateLinksIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find one affiliate-links record
     * @param {AffiliateLinksApiAffiliateLinksIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliateLinksApi
     */
    public affiliateLinksIdGet(requestParameters: AffiliateLinksApiAffiliateLinksIdGetRequest, options?: any) {
        return AffiliateLinksApiFp(this.configuration).affiliateLinksIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a single affiliate-links record
     * @param {AffiliateLinksApiAffiliateLinksIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliateLinksApi
     */
    public affiliateLinksIdPut(requestParameters: AffiliateLinksApiAffiliateLinksIdPutRequest, options?: any) {
        return AffiliateLinksApiFp(this.configuration).affiliateLinksIdPut(requestParameters.id, requestParameters.newAffiliateLinks, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new affiliate-links record
     * @param {AffiliateLinksApiAffiliateLinksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliateLinksApi
     */
    public affiliateLinksPost(requestParameters: AffiliateLinksApiAffiliateLinksPostRequest, options?: any) {
        return AffiliateLinksApiFp(this.configuration).affiliateLinksPost(requestParameters.newAffiliateLinks, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ArticleApi - axios parameter creator
 * @export
 */
export const ArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesCountGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesEditorsPickCountGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/editors-pick/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesEditorsPickGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/editors-pick`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesEvergreenGet: async (limit: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('articlesEvergreenGet', 'limit', limit)
            const localVarPath = `/articles/evergreen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * RSS Feed for latest articles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesFeedGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/feed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('articlesIdDelete', 'id', id)
            const localVarPath = `/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('articlesIdGet', 'id', id)
            const localVarPath = `/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIdNewrelatedGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('articlesIdNewrelatedGet', 'id', id)
            const localVarPath = `/articles/{id}/newrelated`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewArticle} newArticle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIdPut: async (id: string, newArticle: NewArticle, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('articlesIdPut', 'id', id)
            // verify required parameter 'newArticle' is not null or undefined
            assertParamExists('articlesIdPut', 'newArticle', newArticle)
            const localVarPath = `/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newArticle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIdRelatedByIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('articlesIdRelatedByIdGet', 'id', id)
            const localVarPath = `/articles/{id}/relatedById`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIdRelatedGet: async (id: string, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('articlesIdRelatedGet', 'id', id)
            const localVarPath = `/articles/{id}/related`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Increase article views
         * @param {string} id 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIncreaseViewsIdPut: async (id: string, body?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('articlesIncreaseViewsIdPut', 'id', id)
            const localVarPath = `/articles/increaseViews/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesNewsCmcGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/news-cmc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesNewsSitemapGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/newsSitemap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * RSS Feed for latest news
         * @param {string} [aggregator] Query string concat to every link in the feed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesNewsfeedGet: async (aggregator?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/newsfeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (aggregator !== undefined) {
                localVarQueryParameter['aggregator'] = aggregator;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesNextGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/next`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new record
         * @param {NewArticle} newArticle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesPost: async (newArticle: NewArticle, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newArticle' is not null or undefined
            assertParamExists('articlesPost', 'newArticle', newArticle)
            const localVarPath = `/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newArticle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint for creating a new press release
         * @param {PressReleaseBody} pressReleaseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesPressReleasePost: async (pressReleaseBody: PressReleaseBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pressReleaseBody' is not null or undefined
            assertParamExists('articlesPressReleasePost', 'pressReleaseBody', pressReleaseBody)
            const localVarPath = `/articles/pressRelease`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pressReleaseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesPressfeedGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/pressfeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesSnfeedGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/snfeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesTopCountGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/top/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesTopGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesTopReadsGet: async (limit: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('articlesTopReadsGet', 'limit', limit)
            const localVarPath = `/articles/topReads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleApi - functional programming interface
 * @export
 */
export const ArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesCountGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesCountGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesEditorsPickCountGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesEditorsPickCountGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesEditorsPickGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Article>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesEditorsPickGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesEvergreenGet(limit: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Article>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesEvergreenGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * RSS Feed for latest articles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesFeedGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesFeedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Article>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesIdNewrelatedGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RelatedArticle>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesIdNewrelatedGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewArticle} newArticle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesIdPut(id: string, newArticle: NewArticle, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesIdPut(id, newArticle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesIdRelatedByIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesIdRelatedByIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesIdRelatedGet(id: string, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RelatedArticle>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesIdRelatedGet(id, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Increase article views
         * @param {string} id 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesIncreaseViewsIdPut(id: string, body?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesIncreaseViewsIdPut(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesNewsCmcGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesNewsCmcGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesNewsSitemapGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesNewsSitemapGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * RSS Feed for latest news
         * @param {string} [aggregator] Query string concat to every link in the feed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesNewsfeedGet(aggregator?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesNewsfeedGet(aggregator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesNextGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesNextGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new record
         * @param {NewArticle} newArticle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesPost(newArticle: NewArticle, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesPost(newArticle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint for creating a new press release
         * @param {PressReleaseBody} pressReleaseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesPressReleasePost(pressReleaseBody: PressReleaseBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesPressReleasePost(pressReleaseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesPressfeedGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesPressfeedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesSnfeedGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesSnfeedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesTopCountGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesTopCountGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesTopGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Article>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesTopGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesTopReadsGet(limit: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Article>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesTopReadsGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArticleApi - factory interface
 * @export
 */
export const ArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesCountGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.articlesCountGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesEditorsPickCountGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.articlesEditorsPickCountGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesEditorsPickGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<Article>> {
            return localVarFp.articlesEditorsPickGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesEvergreenGet(limit: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<Article>> {
            return localVarFp.articlesEvergreenGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * RSS Feed for latest articles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesFeedGet(options?: any): AxiosPromise<string> {
            return localVarFp.articlesFeedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<Article>> {
            return localVarFp.articlesGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIdDelete(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.articlesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIdGet(id: string, options?: any): AxiosPromise<Article> {
            return localVarFp.articlesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIdNewrelatedGet(id: string, options?: any): AxiosPromise<Array<RelatedArticle>> {
            return localVarFp.articlesIdNewrelatedGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewArticle} newArticle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIdPut(id: string, newArticle: NewArticle, options?: any): AxiosPromise<Article> {
            return localVarFp.articlesIdPut(id, newArticle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIdRelatedByIdGet(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.articlesIdRelatedByIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIdRelatedGet(id: string, limit?: number, options?: any): AxiosPromise<Array<RelatedArticle>> {
            return localVarFp.articlesIdRelatedGet(id, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Increase article views
         * @param {string} id 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesIncreaseViewsIdPut(id: string, body?: any, options?: any): AxiosPromise<number> {
            return localVarFp.articlesIncreaseViewsIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesNewsCmcGet(options?: any): AxiosPromise<object> {
            return localVarFp.articlesNewsCmcGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesNewsSitemapGet(options?: any): AxiosPromise<object> {
            return localVarFp.articlesNewsSitemapGet(options).then((request) => request(axios, basePath));
        },
        /**
         * RSS Feed for latest news
         * @param {string} [aggregator] Query string concat to every link in the feed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesNewsfeedGet(aggregator?: string, options?: any): AxiosPromise<string> {
            return localVarFp.articlesNewsfeedGet(aggregator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesNextGet(options?: any): AxiosPromise<Article> {
            return localVarFp.articlesNextGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new record
         * @param {NewArticle} newArticle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesPost(newArticle: NewArticle, options?: any): AxiosPromise<Article> {
            return localVarFp.articlesPost(newArticle, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint for creating a new press release
         * @param {PressReleaseBody} pressReleaseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesPressReleasePost(pressReleaseBody: PressReleaseBody, options?: any): AxiosPromise<Article> {
            return localVarFp.articlesPressReleasePost(pressReleaseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesPressfeedGet(options?: any): AxiosPromise<object> {
            return localVarFp.articlesPressfeedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesSnfeedGet(options?: any): AxiosPromise<object> {
            return localVarFp.articlesSnfeedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesTopCountGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.articlesTopCountGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesTopGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<Article>> {
            return localVarFp.articlesTopGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesTopReadsGet(limit: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<Article>> {
            return localVarFp.articlesTopReadsGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for articlesCountGet operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesCountGetRequest
 */
export interface ArticleApiArticlesCountGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof ArticleApiArticlesCountGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof ArticleApiArticlesCountGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof ArticleApiArticlesCountGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof ArticleApiArticlesCountGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof ArticleApiArticlesCountGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof ArticleApiArticlesCountGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof ArticleApiArticlesCountGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof ArticleApiArticlesCountGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof ArticleApiArticlesCountGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof ArticleApiArticlesCountGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesCountGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesCountGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for articlesEditorsPickCountGet operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesEditorsPickCountGetRequest
 */
export interface ArticleApiArticlesEditorsPickCountGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof ArticleApiArticlesEditorsPickCountGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickCountGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof ArticleApiArticlesEditorsPickCountGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickCountGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickCountGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickCountGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickCountGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickCountGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickCountGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickCountGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesEditorsPickCountGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesEditorsPickCountGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for articlesEditorsPickGet operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesEditorsPickGetRequest
 */
export interface ArticleApiArticlesEditorsPickGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof ArticleApiArticlesEditorsPickGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof ArticleApiArticlesEditorsPickGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof ArticleApiArticlesEditorsPickGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesEditorsPickGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesEditorsPickGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for articlesEvergreenGet operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesEvergreenGetRequest
 */
export interface ArticleApiArticlesEvergreenGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof ArticleApiArticlesEvergreenGet
     */
    readonly limit: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof ArticleApiArticlesEvergreenGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof ArticleApiArticlesEvergreenGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof ArticleApiArticlesEvergreenGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof ArticleApiArticlesEvergreenGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof ArticleApiArticlesEvergreenGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof ArticleApiArticlesEvergreenGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof ArticleApiArticlesEvergreenGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof ArticleApiArticlesEvergreenGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof ArticleApiArticlesEvergreenGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesEvergreenGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesEvergreenGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for articlesGet operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesGetRequest
 */
export interface ArticleApiArticlesGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof ArticleApiArticlesGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof ArticleApiArticlesGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof ArticleApiArticlesGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof ArticleApiArticlesGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof ArticleApiArticlesGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof ArticleApiArticlesGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof ArticleApiArticlesGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof ArticleApiArticlesGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof ArticleApiArticlesGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof ArticleApiArticlesGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for articlesIdDelete operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesIdDeleteRequest
 */
export interface ArticleApiArticlesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticleApiArticlesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for articlesIdGet operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesIdGetRequest
 */
export interface ArticleApiArticlesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticleApiArticlesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for articlesIdNewrelatedGet operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesIdNewrelatedGetRequest
 */
export interface ArticleApiArticlesIdNewrelatedGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticleApiArticlesIdNewrelatedGet
     */
    readonly id: string
}

/**
 * Request parameters for articlesIdPut operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesIdPutRequest
 */
export interface ArticleApiArticlesIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticleApiArticlesIdPut
     */
    readonly id: string

    /**
     * 
     * @type {NewArticle}
     * @memberof ArticleApiArticlesIdPut
     */
    readonly newArticle: NewArticle
}

/**
 * Request parameters for articlesIdRelatedByIdGet operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesIdRelatedByIdGetRequest
 */
export interface ArticleApiArticlesIdRelatedByIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticleApiArticlesIdRelatedByIdGet
     */
    readonly id: string
}

/**
 * Request parameters for articlesIdRelatedGet operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesIdRelatedGetRequest
 */
export interface ArticleApiArticlesIdRelatedGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticleApiArticlesIdRelatedGet
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof ArticleApiArticlesIdRelatedGet
     */
    readonly limit?: number
}

/**
 * Request parameters for articlesIncreaseViewsIdPut operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesIncreaseViewsIdPutRequest
 */
export interface ArticleApiArticlesIncreaseViewsIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticleApiArticlesIncreaseViewsIdPut
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof ArticleApiArticlesIncreaseViewsIdPut
     */
    readonly body?: any
}

/**
 * Request parameters for articlesNewsfeedGet operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesNewsfeedGetRequest
 */
export interface ArticleApiArticlesNewsfeedGetRequest {
    /**
     * Query string concat to every link in the feed
     * @type {string}
     * @memberof ArticleApiArticlesNewsfeedGet
     */
    readonly aggregator?: string
}

/**
 * Request parameters for articlesPost operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesPostRequest
 */
export interface ArticleApiArticlesPostRequest {
    /**
     * 
     * @type {NewArticle}
     * @memberof ArticleApiArticlesPost
     */
    readonly newArticle: NewArticle
}

/**
 * Request parameters for articlesPressReleasePost operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesPressReleasePostRequest
 */
export interface ArticleApiArticlesPressReleasePostRequest {
    /**
     * 
     * @type {PressReleaseBody}
     * @memberof ArticleApiArticlesPressReleasePost
     */
    readonly pressReleaseBody: PressReleaseBody
}

/**
 * Request parameters for articlesTopCountGet operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesTopCountGetRequest
 */
export interface ArticleApiArticlesTopCountGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof ArticleApiArticlesTopCountGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof ArticleApiArticlesTopCountGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof ArticleApiArticlesTopCountGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof ArticleApiArticlesTopCountGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof ArticleApiArticlesTopCountGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof ArticleApiArticlesTopCountGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof ArticleApiArticlesTopCountGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof ArticleApiArticlesTopCountGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof ArticleApiArticlesTopCountGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof ArticleApiArticlesTopCountGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesTopCountGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesTopCountGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for articlesTopGet operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesTopGetRequest
 */
export interface ArticleApiArticlesTopGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof ArticleApiArticlesTopGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof ArticleApiArticlesTopGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof ArticleApiArticlesTopGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof ArticleApiArticlesTopGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof ArticleApiArticlesTopGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof ArticleApiArticlesTopGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof ArticleApiArticlesTopGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof ArticleApiArticlesTopGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof ArticleApiArticlesTopGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof ArticleApiArticlesTopGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesTopGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesTopGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for articlesTopReadsGet operation in ArticleApi.
 * @export
 * @interface ArticleApiArticlesTopReadsGetRequest
 */
export interface ArticleApiArticlesTopReadsGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof ArticleApiArticlesTopReadsGet
     */
    readonly limit: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof ArticleApiArticlesTopReadsGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof ArticleApiArticlesTopReadsGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof ArticleApiArticlesTopReadsGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof ArticleApiArticlesTopReadsGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof ArticleApiArticlesTopReadsGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof ArticleApiArticlesTopReadsGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof ArticleApiArticlesTopReadsGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof ArticleApiArticlesTopReadsGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof ArticleApiArticlesTopReadsGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesTopReadsGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleApiArticlesTopReadsGet
     */
    readonly nin?: Array<string>
}

/**
 * ArticleApi - object-oriented interface
 * @export
 * @class ArticleApi
 * @extends {BaseAPI}
 */
export class ArticleApi extends BaseAPI {
    /**
     * 
     * @param {ArticleApiArticlesCountGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesCountGet(requestParameters: ArticleApiArticlesCountGetRequest = {}, options?: any) {
        return ArticleApiFp(this.configuration).articlesCountGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleApiArticlesEditorsPickCountGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesEditorsPickCountGet(requestParameters: ArticleApiArticlesEditorsPickCountGetRequest = {}, options?: any) {
        return ArticleApiFp(this.configuration).articlesEditorsPickCountGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleApiArticlesEditorsPickGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesEditorsPickGet(requestParameters: ArticleApiArticlesEditorsPickGetRequest = {}, options?: any) {
        return ArticleApiFp(this.configuration).articlesEditorsPickGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleApiArticlesEvergreenGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesEvergreenGet(requestParameters: ArticleApiArticlesEvergreenGetRequest, options?: any) {
        return ArticleApiFp(this.configuration).articlesEvergreenGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * RSS Feed for latest articles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesFeedGet(options?: any) {
        return ArticleApiFp(this.configuration).articlesFeedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleApiArticlesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesGet(requestParameters: ArticleApiArticlesGetRequest = {}, options?: any) {
        return ArticleApiFp(this.configuration).articlesGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a record
     * @param {ArticleApiArticlesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesIdDelete(requestParameters: ArticleApiArticlesIdDeleteRequest, options?: any) {
        return ArticleApiFp(this.configuration).articlesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleApiArticlesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesIdGet(requestParameters: ArticleApiArticlesIdGetRequest, options?: any) {
        return ArticleApiFp(this.configuration).articlesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleApiArticlesIdNewrelatedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesIdNewrelatedGet(requestParameters: ArticleApiArticlesIdNewrelatedGetRequest, options?: any) {
        return ArticleApiFp(this.configuration).articlesIdNewrelatedGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a record
     * @param {ArticleApiArticlesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesIdPut(requestParameters: ArticleApiArticlesIdPutRequest, options?: any) {
        return ArticleApiFp(this.configuration).articlesIdPut(requestParameters.id, requestParameters.newArticle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleApiArticlesIdRelatedByIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesIdRelatedByIdGet(requestParameters: ArticleApiArticlesIdRelatedByIdGetRequest, options?: any) {
        return ArticleApiFp(this.configuration).articlesIdRelatedByIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleApiArticlesIdRelatedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesIdRelatedGet(requestParameters: ArticleApiArticlesIdRelatedGetRequest, options?: any) {
        return ArticleApiFp(this.configuration).articlesIdRelatedGet(requestParameters.id, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Increase article views
     * @param {ArticleApiArticlesIncreaseViewsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesIncreaseViewsIdPut(requestParameters: ArticleApiArticlesIncreaseViewsIdPutRequest, options?: any) {
        return ArticleApiFp(this.configuration).articlesIncreaseViewsIdPut(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesNewsCmcGet(options?: any) {
        return ArticleApiFp(this.configuration).articlesNewsCmcGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesNewsSitemapGet(options?: any) {
        return ArticleApiFp(this.configuration).articlesNewsSitemapGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * RSS Feed for latest news
     * @param {ArticleApiArticlesNewsfeedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesNewsfeedGet(requestParameters: ArticleApiArticlesNewsfeedGetRequest = {}, options?: any) {
        return ArticleApiFp(this.configuration).articlesNewsfeedGet(requestParameters.aggregator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesNextGet(options?: any) {
        return ArticleApiFp(this.configuration).articlesNextGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new record
     * @param {ArticleApiArticlesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesPost(requestParameters: ArticleApiArticlesPostRequest, options?: any) {
        return ArticleApiFp(this.configuration).articlesPost(requestParameters.newArticle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint for creating a new press release
     * @param {ArticleApiArticlesPressReleasePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesPressReleasePost(requestParameters: ArticleApiArticlesPressReleasePostRequest, options?: any) {
        return ArticleApiFp(this.configuration).articlesPressReleasePost(requestParameters.pressReleaseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesPressfeedGet(options?: any) {
        return ArticleApiFp(this.configuration).articlesPressfeedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesSnfeedGet(options?: any) {
        return ArticleApiFp(this.configuration).articlesSnfeedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleApiArticlesTopCountGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesTopCountGet(requestParameters: ArticleApiArticlesTopCountGetRequest = {}, options?: any) {
        return ArticleApiFp(this.configuration).articlesTopCountGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleApiArticlesTopGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesTopGet(requestParameters: ArticleApiArticlesTopGetRequest = {}, options?: any) {
        return ArticleApiFp(this.configuration).articlesTopGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleApiArticlesTopReadsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articlesTopReadsGet(requestParameters: ArticleApiArticlesTopReadsGetRequest, options?: any) {
        return ArticleApiFp(this.configuration).articlesTopReadsGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ArticleTemplateApi - axios parameter creator
 * @export
 */
export const ArticleTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleTemplatesCountGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/article-templates/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleTemplatesGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/article-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleTemplatesIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('articleTemplatesIdDelete', 'id', id)
            const localVarPath = `/article-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleTemplatesIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('articleTemplatesIdGet', 'id', id)
            const localVarPath = `/article-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewArticleTemplate} newArticleTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleTemplatesIdPut: async (id: string, newArticleTemplate: NewArticleTemplate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('articleTemplatesIdPut', 'id', id)
            // verify required parameter 'newArticleTemplate' is not null or undefined
            assertParamExists('articleTemplatesIdPut', 'newArticleTemplate', newArticleTemplate)
            const localVarPath = `/article-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newArticleTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new record
         * @param {NewArticleTemplate} newArticleTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleTemplatesPost: async (newArticleTemplate: NewArticleTemplate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newArticleTemplate' is not null or undefined
            assertParamExists('articleTemplatesPost', 'newArticleTemplate', newArticleTemplate)
            const localVarPath = `/article-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newArticleTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleTemplateApi - functional programming interface
 * @export
 */
export const ArticleTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleTemplatesCountGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleTemplatesCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleTemplatesGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleTemplatesGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleTemplatesIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleTemplatesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleTemplatesIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleTemplatesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewArticleTemplate} newArticleTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleTemplatesIdPut(id: string, newArticleTemplate: NewArticleTemplate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleTemplatesIdPut(id, newArticleTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new record
         * @param {NewArticleTemplate} newArticleTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleTemplatesPost(newArticleTemplate: NewArticleTemplate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleTemplatesPost(newArticleTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArticleTemplateApi - factory interface
 * @export
 */
export const ArticleTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleTemplatesCountGet(options?: any): AxiosPromise<object> {
            return localVarFp.articleTemplatesCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleTemplatesGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<object> {
            return localVarFp.articleTemplatesGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleTemplatesIdDelete(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.articleTemplatesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleTemplatesIdGet(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.articleTemplatesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewArticleTemplate} newArticleTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleTemplatesIdPut(id: string, newArticleTemplate: NewArticleTemplate, options?: any): AxiosPromise<object> {
            return localVarFp.articleTemplatesIdPut(id, newArticleTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new record
         * @param {NewArticleTemplate} newArticleTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleTemplatesPost(newArticleTemplate: NewArticleTemplate, options?: any): AxiosPromise<object> {
            return localVarFp.articleTemplatesPost(newArticleTemplate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for articleTemplatesGet operation in ArticleTemplateApi.
 * @export
 * @interface ArticleTemplateApiArticleTemplatesGetRequest
 */
export interface ArticleTemplateApiArticleTemplatesGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof ArticleTemplateApiArticleTemplatesGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof ArticleTemplateApiArticleTemplatesGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof ArticleTemplateApiArticleTemplatesGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof ArticleTemplateApiArticleTemplatesGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof ArticleTemplateApiArticleTemplatesGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof ArticleTemplateApiArticleTemplatesGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof ArticleTemplateApiArticleTemplatesGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof ArticleTemplateApiArticleTemplatesGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof ArticleTemplateApiArticleTemplatesGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof ArticleTemplateApiArticleTemplatesGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleTemplateApiArticleTemplatesGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof ArticleTemplateApiArticleTemplatesGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for articleTemplatesIdDelete operation in ArticleTemplateApi.
 * @export
 * @interface ArticleTemplateApiArticleTemplatesIdDeleteRequest
 */
export interface ArticleTemplateApiArticleTemplatesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticleTemplateApiArticleTemplatesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for articleTemplatesIdGet operation in ArticleTemplateApi.
 * @export
 * @interface ArticleTemplateApiArticleTemplatesIdGetRequest
 */
export interface ArticleTemplateApiArticleTemplatesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticleTemplateApiArticleTemplatesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for articleTemplatesIdPut operation in ArticleTemplateApi.
 * @export
 * @interface ArticleTemplateApiArticleTemplatesIdPutRequest
 */
export interface ArticleTemplateApiArticleTemplatesIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticleTemplateApiArticleTemplatesIdPut
     */
    readonly id: string

    /**
     * 
     * @type {NewArticleTemplate}
     * @memberof ArticleTemplateApiArticleTemplatesIdPut
     */
    readonly newArticleTemplate: NewArticleTemplate
}

/**
 * Request parameters for articleTemplatesPost operation in ArticleTemplateApi.
 * @export
 * @interface ArticleTemplateApiArticleTemplatesPostRequest
 */
export interface ArticleTemplateApiArticleTemplatesPostRequest {
    /**
     * 
     * @type {NewArticleTemplate}
     * @memberof ArticleTemplateApiArticleTemplatesPost
     */
    readonly newArticleTemplate: NewArticleTemplate
}

/**
 * ArticleTemplateApi - object-oriented interface
 * @export
 * @class ArticleTemplateApi
 * @extends {BaseAPI}
 */
export class ArticleTemplateApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleTemplateApi
     */
    public articleTemplatesCountGet(options?: any) {
        return ArticleTemplateApiFp(this.configuration).articleTemplatesCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleTemplateApiArticleTemplatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleTemplateApi
     */
    public articleTemplatesGet(requestParameters: ArticleTemplateApiArticleTemplatesGetRequest = {}, options?: any) {
        return ArticleTemplateApiFp(this.configuration).articleTemplatesGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a record
     * @param {ArticleTemplateApiArticleTemplatesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleTemplateApi
     */
    public articleTemplatesIdDelete(requestParameters: ArticleTemplateApiArticleTemplatesIdDeleteRequest, options?: any) {
        return ArticleTemplateApiFp(this.configuration).articleTemplatesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleTemplateApiArticleTemplatesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleTemplateApi
     */
    public articleTemplatesIdGet(requestParameters: ArticleTemplateApiArticleTemplatesIdGetRequest, options?: any) {
        return ArticleTemplateApiFp(this.configuration).articleTemplatesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a record
     * @param {ArticleTemplateApiArticleTemplatesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleTemplateApi
     */
    public articleTemplatesIdPut(requestParameters: ArticleTemplateApiArticleTemplatesIdPutRequest, options?: any) {
        return ArticleTemplateApiFp(this.configuration).articleTemplatesIdPut(requestParameters.id, requestParameters.newArticleTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new record
     * @param {ArticleTemplateApiArticleTemplatesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleTemplateApi
     */
    public articleTemplatesPost(requestParameters: ArticleTemplateApiArticleTemplatesPostRequest, options?: any) {
        return ArticleTemplateApiFp(this.configuration).articleTemplatesPost(requestParameters.newArticleTemplate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetApi - axios parameter creator
 * @export
 */
export const AssetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} [tickers] Assets tickers.
         * @param {Array<string>} [exclude] Tickers that should be excluded from final result. Ignored if used with tickers parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsGet: async (tickers?: Array<string>, exclude?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tickers) {
                localVarQueryParameter['tickers'] = tickers;
            }

            if (exclude) {
                localVarQueryParameter['exclude'] = exclude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsTickerGet: async (ticker: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticker' is not null or undefined
            assertParamExists('assetsTickerGet', 'ticker', ticker)
            const localVarPath = `/assets/{ticker}`
                .replace(`{${"ticker"}}`, encodeURIComponent(String(ticker)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetApi - functional programming interface
 * @export
 */
export const AssetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [tickers] Assets tickers.
         * @param {Array<string>} [exclude] Tickers that should be excluded from final result. Ignored if used with tickers parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsGet(tickers?: Array<string>, exclude?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Asset>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsGet(tickers, exclude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsTickerGet(ticker: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsTickerGet(ticker, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetApi - factory interface
 * @export
 */
export const AssetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [tickers] Assets tickers.
         * @param {Array<string>} [exclude] Tickers that should be excluded from final result. Ignored if used with tickers parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsGet(tickers?: Array<string>, exclude?: Array<string>, options?: any): AxiosPromise<Array<Asset>> {
            return localVarFp.assetsGet(tickers, exclude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsTickerGet(ticker: string, options?: any): AxiosPromise<AssetInfo> {
            return localVarFp.assetsTickerGet(ticker, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assetsGet operation in AssetApi.
 * @export
 * @interface AssetApiAssetsGetRequest
 */
export interface AssetApiAssetsGetRequest {
    /**
     * Assets tickers.
     * @type {Array<string>}
     * @memberof AssetApiAssetsGet
     */
    readonly tickers?: Array<string>

    /**
     * Tickers that should be excluded from final result. Ignored if used with tickers parameter
     * @type {Array<string>}
     * @memberof AssetApiAssetsGet
     */
    readonly exclude?: Array<string>
}

/**
 * Request parameters for assetsTickerGet operation in AssetApi.
 * @export
 * @interface AssetApiAssetsTickerGetRequest
 */
export interface AssetApiAssetsTickerGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetsTickerGet
     */
    readonly ticker: string
}

/**
 * AssetApi - object-oriented interface
 * @export
 * @class AssetApi
 * @extends {BaseAPI}
 */
export class AssetApi extends BaseAPI {
    /**
     * 
     * @param {AssetApiAssetsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetsGet(requestParameters: AssetApiAssetsGetRequest = {}, options?: any) {
        return AssetApiFp(this.configuration).assetsGet(requestParameters.tickers, requestParameters.exclude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetApiAssetsTickerGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetsTickerGet(requestParameters: AssetApiAssetsTickerGetRequest, options?: any) {
        return AssetApiFp(this.configuration).assetsTickerGet(requestParameters.ticker, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCountGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesIdDelete', 'id', id)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesIdGet', 'id', id)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewCategory} newCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdPut: async (id: string, newCategory: NewCategory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesIdPut', 'id', id)
            // verify required parameter 'newCategory' is not null or undefined
            assertParamExists('categoriesIdPut', 'newCategory', newCategory)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new record
         * @param {NewCategory} newCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPost: async (newCategory: NewCategory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newCategory' is not null or undefined
            assertParamExists('categoriesPost', 'newCategory', newCategory)
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesCountGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewCategory} newCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesIdPut(id: string, newCategory: NewCategory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesIdPut(id, newCategory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new record
         * @param {NewCategory} newCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesPost(newCategory: NewCategory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesPost(newCategory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCountGet(options?: any): AxiosPromise<object> {
            return localVarFp.categoriesCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.categoriesGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdDelete(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.categoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdGet(id: string, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewCategory} newCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdPut(id: string, newCategory: NewCategory, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesIdPut(id, newCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new record
         * @param {NewCategory} newCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPost(newCategory: NewCategory, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesPost(newCategory, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for categoriesGet operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoriesGetRequest
 */
export interface CategoryApiCategoriesGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof CategoryApiCategoriesGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof CategoryApiCategoriesGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof CategoryApiCategoriesGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof CategoryApiCategoriesGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof CategoryApiCategoriesGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof CategoryApiCategoriesGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof CategoryApiCategoriesGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof CategoryApiCategoriesGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof CategoryApiCategoriesGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof CategoryApiCategoriesGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof CategoryApiCategoriesGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof CategoryApiCategoriesGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for categoriesIdDelete operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoriesIdDeleteRequest
 */
export interface CategoryApiCategoriesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoryApiCategoriesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for categoriesIdGet operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoriesIdGetRequest
 */
export interface CategoryApiCategoriesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoryApiCategoriesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for categoriesIdPut operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoriesIdPutRequest
 */
export interface CategoryApiCategoriesIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoryApiCategoriesIdPut
     */
    readonly id: string

    /**
     * 
     * @type {NewCategory}
     * @memberof CategoryApiCategoriesIdPut
     */
    readonly newCategory: NewCategory
}

/**
 * Request parameters for categoriesPost operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoriesPostRequest
 */
export interface CategoryApiCategoriesPostRequest {
    /**
     * 
     * @type {NewCategory}
     * @memberof CategoryApiCategoriesPost
     */
    readonly newCategory: NewCategory
}

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoriesCountGet(options?: any) {
        return CategoryApiFp(this.configuration).categoriesCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryApiCategoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoriesGet(requestParameters: CategoryApiCategoriesGetRequest = {}, options?: any) {
        return CategoryApiFp(this.configuration).categoriesGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a record
     * @param {CategoryApiCategoriesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoriesIdDelete(requestParameters: CategoryApiCategoriesIdDeleteRequest, options?: any) {
        return CategoryApiFp(this.configuration).categoriesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryApiCategoriesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoriesIdGet(requestParameters: CategoryApiCategoriesIdGetRequest, options?: any) {
        return CategoryApiFp(this.configuration).categoriesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a record
     * @param {CategoryApiCategoriesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoriesIdPut(requestParameters: CategoryApiCategoriesIdPutRequest, options?: any) {
        return CategoryApiFp(this.configuration).categoriesIdPut(requestParameters.id, requestParameters.newCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new record
     * @param {CategoryApiCategoriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoriesPost(requestParameters: CategoryApiCategoriesPostRequest, options?: any) {
        return CategoryApiFp(this.configuration).categoriesPost(requestParameters.newCategory, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CollectionApi - axios parameter creator
 * @export
 */
export const CollectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCountGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/collections/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('collectionsIdDelete', 'id', id)
            const localVarPath = `/collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('collectionsIdGet', 'id', id)
            const localVarPath = `/collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewCollection} newCollection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsIdPut: async (id: string, newCollection: NewCollection, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('collectionsIdPut', 'id', id)
            // verify required parameter 'newCollection' is not null or undefined
            assertParamExists('collectionsIdPut', 'newCollection', newCollection)
            const localVarPath = `/collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCollection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new record
         * @param {NewCollection} newCollection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsPost: async (newCollection: NewCollection, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newCollection' is not null or undefined
            assertParamExists('collectionsPost', 'newCollection', newCollection)
            const localVarPath = `/collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCollection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollectionApi - functional programming interface
 * @export
 */
export const CollectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CollectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCountGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Collection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Collection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewCollection} newCollection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsIdPut(id: string, newCollection: NewCollection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Collection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsIdPut(id, newCollection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new record
         * @param {NewCollection} newCollection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsPost(newCollection: NewCollection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Collection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsPost(newCollection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CollectionApi - factory interface
 * @export
 */
export const CollectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CollectionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCountGet(options?: any): AxiosPromise<object> {
            return localVarFp.collectionsCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<Collection>> {
            return localVarFp.collectionsGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsIdDelete(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.collectionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsIdGet(id: string, options?: any): AxiosPromise<Collection> {
            return localVarFp.collectionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewCollection} newCollection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsIdPut(id: string, newCollection: NewCollection, options?: any): AxiosPromise<Collection> {
            return localVarFp.collectionsIdPut(id, newCollection, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new record
         * @param {NewCollection} newCollection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsPost(newCollection: NewCollection, options?: any): AxiosPromise<Collection> {
            return localVarFp.collectionsPost(newCollection, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for collectionsGet operation in CollectionApi.
 * @export
 * @interface CollectionApiCollectionsGetRequest
 */
export interface CollectionApiCollectionsGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof CollectionApiCollectionsGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof CollectionApiCollectionsGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof CollectionApiCollectionsGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof CollectionApiCollectionsGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof CollectionApiCollectionsGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof CollectionApiCollectionsGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof CollectionApiCollectionsGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof CollectionApiCollectionsGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof CollectionApiCollectionsGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof CollectionApiCollectionsGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof CollectionApiCollectionsGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof CollectionApiCollectionsGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for collectionsIdDelete operation in CollectionApi.
 * @export
 * @interface CollectionApiCollectionsIdDeleteRequest
 */
export interface CollectionApiCollectionsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CollectionApiCollectionsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for collectionsIdGet operation in CollectionApi.
 * @export
 * @interface CollectionApiCollectionsIdGetRequest
 */
export interface CollectionApiCollectionsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CollectionApiCollectionsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for collectionsIdPut operation in CollectionApi.
 * @export
 * @interface CollectionApiCollectionsIdPutRequest
 */
export interface CollectionApiCollectionsIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CollectionApiCollectionsIdPut
     */
    readonly id: string

    /**
     * 
     * @type {NewCollection}
     * @memberof CollectionApiCollectionsIdPut
     */
    readonly newCollection: NewCollection
}

/**
 * Request parameters for collectionsPost operation in CollectionApi.
 * @export
 * @interface CollectionApiCollectionsPostRequest
 */
export interface CollectionApiCollectionsPostRequest {
    /**
     * 
     * @type {NewCollection}
     * @memberof CollectionApiCollectionsPost
     */
    readonly newCollection: NewCollection
}

/**
 * CollectionApi - object-oriented interface
 * @export
 * @class CollectionApi
 * @extends {BaseAPI}
 */
export class CollectionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsCountGet(options?: any) {
        return CollectionApiFp(this.configuration).collectionsCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CollectionApiCollectionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsGet(requestParameters: CollectionApiCollectionsGetRequest = {}, options?: any) {
        return CollectionApiFp(this.configuration).collectionsGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a record
     * @param {CollectionApiCollectionsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsIdDelete(requestParameters: CollectionApiCollectionsIdDeleteRequest, options?: any) {
        return CollectionApiFp(this.configuration).collectionsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CollectionApiCollectionsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsIdGet(requestParameters: CollectionApiCollectionsIdGetRequest, options?: any) {
        return CollectionApiFp(this.configuration).collectionsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a record
     * @param {CollectionApiCollectionsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsIdPut(requestParameters: CollectionApiCollectionsIdPutRequest, options?: any) {
        return CollectionApiFp(this.configuration).collectionsIdPut(requestParameters.id, requestParameters.newCollection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new record
     * @param {CollectionApiCollectionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsPost(requestParameters: CollectionApiCollectionsPostRequest, options?: any) {
        return CollectionApiFp(this.configuration).collectionsPost(requestParameters.newCollection, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a single contact record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all the contact\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a single contact record
         * @param {NewContact} newContact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactPut: async (newContact: NewContact, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newContact' is not null or undefined
            assertParamExists('contactPut', 'newContact', newContact)
            const localVarPath = `/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newContact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a single contact record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all the contact\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a single contact record
         * @param {NewContact} newContact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactPut(newContact: NewContact, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactPut(newContact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactApiFp(configuration)
    return {
        /**
         * Delete a single contact record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactDelete(options?: any): AxiosPromise<number> {
            return localVarFp.contactDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Find all the contact\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Contact> {
            return localVarFp.contactGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a single contact record
         * @param {NewContact} newContact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactPut(newContact: NewContact, options?: any): AxiosPromise<Contact> {
            return localVarFp.contactPut(newContact, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for contactGet operation in ContactApi.
 * @export
 * @interface ContactApiContactGetRequest
 */
export interface ContactApiContactGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof ContactApiContactGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof ContactApiContactGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof ContactApiContactGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof ContactApiContactGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof ContactApiContactGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof ContactApiContactGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof ContactApiContactGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof ContactApiContactGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof ContactApiContactGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof ContactApiContactGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof ContactApiContactGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof ContactApiContactGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for contactPut operation in ContactApi.
 * @export
 * @interface ContactApiContactPutRequest
 */
export interface ContactApiContactPutRequest {
    /**
     * 
     * @type {NewContact}
     * @memberof ContactApiContactPut
     */
    readonly newContact: NewContact
}

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * Delete a single contact record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public contactDelete(options?: any) {
        return ContactApiFp(this.configuration).contactDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all the contact\'s records
     * @param {ContactApiContactGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public contactGet(requestParameters: ContactApiContactGetRequest = {}, options?: any) {
        return ContactApiFp(this.configuration).contactGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a single contact record
     * @param {ContactApiContactPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public contactPut(requestParameters: ContactApiContactPutRequest, options?: any) {
        return ContactApiFp(this.configuration).contactPut(requestParameters.newContact, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeletedLinksApi - axios parameter creator
 * @export
 */
export const DeletedLinksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the numver of deleted-links documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletedLinksCountGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/deleted-links/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all the deleted-links\'s records
         * @param {'article' | 'category' | 'subcategory' | 'tag' | 'writer'} [type] Filter records by type
         * @param {string} [slug] Filter records by slug
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletedLinksGet: async (type?: 'article' | 'category' | 'subcategory' | 'tag' | 'writer', slug?: string, limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/deleted-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a single deleted-links record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletedLinksIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletedLinksIdDelete', 'id', id)
            const localVarPath = `/deleted-links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a single deleted-links record
         * @param {string} id 
         * @param {NewDeletedLinks} newDeletedLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletedLinksIdPut: async (id: string, newDeletedLinks: NewDeletedLinks, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletedLinksIdPut', 'id', id)
            // verify required parameter 'newDeletedLinks' is not null or undefined
            assertParamExists('deletedLinksIdPut', 'newDeletedLinks', newDeletedLinks)
            const localVarPath = `/deleted-links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newDeletedLinks, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new deleted-links record
         * @param {NewDeletedLinks} newDeletedLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletedLinksPost: async (newDeletedLinks: NewDeletedLinks, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newDeletedLinks' is not null or undefined
            assertParamExists('deletedLinksPost', 'newDeletedLinks', newDeletedLinks)
            const localVarPath = `/deleted-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newDeletedLinks, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeletedLinksApi - functional programming interface
 * @export
 */
export const DeletedLinksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeletedLinksApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the numver of deleted-links documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletedLinksCountGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletedLinksCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all the deleted-links\'s records
         * @param {'article' | 'category' | 'subcategory' | 'tag' | 'writer'} [type] Filter records by type
         * @param {string} [slug] Filter records by slug
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletedLinksGet(type?: 'article' | 'category' | 'subcategory' | 'tag' | 'writer', slug?: string, limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeletedLinks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletedLinksGet(type, slug, limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a single deleted-links record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletedLinksIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletedLinksIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a single deleted-links record
         * @param {string} id 
         * @param {NewDeletedLinks} newDeletedLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletedLinksIdPut(id: string, newDeletedLinks: NewDeletedLinks, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletedLinksIdPut(id, newDeletedLinks, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new deleted-links record
         * @param {NewDeletedLinks} newDeletedLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletedLinksPost(newDeletedLinks: NewDeletedLinks, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletedLinksPost(newDeletedLinks, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeletedLinksApi - factory interface
 * @export
 */
export const DeletedLinksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeletedLinksApiFp(configuration)
    return {
        /**
         * Retrieve the numver of deleted-links documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletedLinksCountGet(options?: any): AxiosPromise<object> {
            return localVarFp.deletedLinksCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Find all the deleted-links\'s records
         * @param {'article' | 'category' | 'subcategory' | 'tag' | 'writer'} [type] Filter records by type
         * @param {string} [slug] Filter records by slug
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletedLinksGet(type?: 'article' | 'category' | 'subcategory' | 'tag' | 'writer', slug?: string, limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<DeletedLinks> {
            return localVarFp.deletedLinksGet(type, slug, limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a single deleted-links record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletedLinksIdDelete(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.deletedLinksIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a single deleted-links record
         * @param {string} id 
         * @param {NewDeletedLinks} newDeletedLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletedLinksIdPut(id: string, newDeletedLinks: NewDeletedLinks, options?: any): AxiosPromise<object> {
            return localVarFp.deletedLinksIdPut(id, newDeletedLinks, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new deleted-links record
         * @param {NewDeletedLinks} newDeletedLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletedLinksPost(newDeletedLinks: NewDeletedLinks, options?: any): AxiosPromise<object> {
            return localVarFp.deletedLinksPost(newDeletedLinks, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deletedLinksGet operation in DeletedLinksApi.
 * @export
 * @interface DeletedLinksApiDeletedLinksGetRequest
 */
export interface DeletedLinksApiDeletedLinksGetRequest {
    /**
     * Filter records by type
     * @type {'article' | 'category' | 'subcategory' | 'tag' | 'writer'}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly type?: 'article' | 'category' | 'subcategory' | 'tag' | 'writer'

    /**
     * Filter records by slug
     * @type {string}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly slug?: string

    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof DeletedLinksApiDeletedLinksGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for deletedLinksIdDelete operation in DeletedLinksApi.
 * @export
 * @interface DeletedLinksApiDeletedLinksIdDeleteRequest
 */
export interface DeletedLinksApiDeletedLinksIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DeletedLinksApiDeletedLinksIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for deletedLinksIdPut operation in DeletedLinksApi.
 * @export
 * @interface DeletedLinksApiDeletedLinksIdPutRequest
 */
export interface DeletedLinksApiDeletedLinksIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DeletedLinksApiDeletedLinksIdPut
     */
    readonly id: string

    /**
     * 
     * @type {NewDeletedLinks}
     * @memberof DeletedLinksApiDeletedLinksIdPut
     */
    readonly newDeletedLinks: NewDeletedLinks
}

/**
 * Request parameters for deletedLinksPost operation in DeletedLinksApi.
 * @export
 * @interface DeletedLinksApiDeletedLinksPostRequest
 */
export interface DeletedLinksApiDeletedLinksPostRequest {
    /**
     * 
     * @type {NewDeletedLinks}
     * @memberof DeletedLinksApiDeletedLinksPost
     */
    readonly newDeletedLinks: NewDeletedLinks
}

/**
 * DeletedLinksApi - object-oriented interface
 * @export
 * @class DeletedLinksApi
 * @extends {BaseAPI}
 */
export class DeletedLinksApi extends BaseAPI {
    /**
     * Retrieve the numver of deleted-links documents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeletedLinksApi
     */
    public deletedLinksCountGet(options?: any) {
        return DeletedLinksApiFp(this.configuration).deletedLinksCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all the deleted-links\'s records
     * @param {DeletedLinksApiDeletedLinksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeletedLinksApi
     */
    public deletedLinksGet(requestParameters: DeletedLinksApiDeletedLinksGetRequest = {}, options?: any) {
        return DeletedLinksApiFp(this.configuration).deletedLinksGet(requestParameters.type, requestParameters.slug, requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a single deleted-links record
     * @param {DeletedLinksApiDeletedLinksIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeletedLinksApi
     */
    public deletedLinksIdDelete(requestParameters: DeletedLinksApiDeletedLinksIdDeleteRequest, options?: any) {
        return DeletedLinksApiFp(this.configuration).deletedLinksIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a single deleted-links record
     * @param {DeletedLinksApiDeletedLinksIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeletedLinksApi
     */
    public deletedLinksIdPut(requestParameters: DeletedLinksApiDeletedLinksIdPutRequest, options?: any) {
        return DeletedLinksApiFp(this.configuration).deletedLinksIdPut(requestParameters.id, requestParameters.newDeletedLinks, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new deleted-links record
     * @param {DeletedLinksApiDeletedLinksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeletedLinksApi
     */
    public deletedLinksPost(requestParameters: DeletedLinksApiDeletedLinksPostRequest, options?: any) {
        return DeletedLinksApiFp(this.configuration).deletedLinksPost(requestParameters.newDeletedLinks, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocsApi - axios parameter creator
 * @export
 */
export const DocsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsOpenapiJsonGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/docs/openapi.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} major 
         * @param {string} minor 
         * @param {string} patch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsVmajorMinorPatchOpenapiJsonGet: async (major: string, minor: string, patch: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'major' is not null or undefined
            assertParamExists('docsVmajorMinorPatchOpenapiJsonGet', 'major', major)
            // verify required parameter 'minor' is not null or undefined
            assertParamExists('docsVmajorMinorPatchOpenapiJsonGet', 'minor', minor)
            // verify required parameter 'patch' is not null or undefined
            assertParamExists('docsVmajorMinorPatchOpenapiJsonGet', 'patch', patch)
            const localVarPath = `/docs/v{major}.{minor}.{patch}/openapi.json`
                .replace(`{${"major"}}`, encodeURIComponent(String(major)))
                .replace(`{${"minor"}}`, encodeURIComponent(String(minor)))
                .replace(`{${"patch"}}`, encodeURIComponent(String(patch)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocsApi - functional programming interface
 * @export
 */
export const DocsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsOpenapiJsonGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsOpenapiJsonGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} major 
         * @param {string} minor 
         * @param {string} patch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsVmajorMinorPatchOpenapiJsonGet(major: string, minor: string, patch: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsVmajorMinorPatchOpenapiJsonGet(major, minor, patch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocsApi - factory interface
 * @export
 */
export const DocsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsOpenapiJsonGet(options?: any): AxiosPromise<object> {
            return localVarFp.docsOpenapiJsonGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} major 
         * @param {string} minor 
         * @param {string} patch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsVmajorMinorPatchOpenapiJsonGet(major: string, minor: string, patch: string, options?: any): AxiosPromise<object> {
            return localVarFp.docsVmajorMinorPatchOpenapiJsonGet(major, minor, patch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for docsVmajorMinorPatchOpenapiJsonGet operation in DocsApi.
 * @export
 * @interface DocsApiDocsVmajorMinorPatchOpenapiJsonGetRequest
 */
export interface DocsApiDocsVmajorMinorPatchOpenapiJsonGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DocsApiDocsVmajorMinorPatchOpenapiJsonGet
     */
    readonly major: string

    /**
     * 
     * @type {string}
     * @memberof DocsApiDocsVmajorMinorPatchOpenapiJsonGet
     */
    readonly minor: string

    /**
     * 
     * @type {string}
     * @memberof DocsApiDocsVmajorMinorPatchOpenapiJsonGet
     */
    readonly patch: string
}

/**
 * DocsApi - object-oriented interface
 * @export
 * @class DocsApi
 * @extends {BaseAPI}
 */
export class DocsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public docsOpenapiJsonGet(options?: any) {
        return DocsApiFp(this.configuration).docsOpenapiJsonGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocsApiDocsVmajorMinorPatchOpenapiJsonGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public docsVmajorMinorPatchOpenapiJsonGet(requestParameters: DocsApiDocsVmajorMinorPatchOpenapiJsonGetRequest, options?: any) {
        return DocsApiFp(this.configuration).docsVmajorMinorPatchOpenapiJsonGet(requestParameters.major, requestParameters.minor, requestParameters.patch, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FooterApi - axios parameter creator
 * @export
 */
export const FooterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a single footer record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/footer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all the footer\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/footer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a single footer record
         * @param {NewFooter} newFooter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerPut: async (newFooter: NewFooter, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newFooter' is not null or undefined
            assertParamExists('footerPut', 'newFooter', newFooter)
            const localVarPath = `/footer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newFooter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FooterApi - functional programming interface
 * @export
 */
export const FooterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FooterApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a single footer record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async footerDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.footerDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all the footer\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async footerGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Footer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.footerGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a single footer record
         * @param {NewFooter} newFooter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async footerPut(newFooter: NewFooter, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Footer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.footerPut(newFooter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FooterApi - factory interface
 * @export
 */
export const FooterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FooterApiFp(configuration)
    return {
        /**
         * Delete a single footer record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerDelete(options?: any): AxiosPromise<number> {
            return localVarFp.footerDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Find all the footer\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Footer> {
            return localVarFp.footerGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a single footer record
         * @param {NewFooter} newFooter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerPut(newFooter: NewFooter, options?: any): AxiosPromise<Footer> {
            return localVarFp.footerPut(newFooter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for footerGet operation in FooterApi.
 * @export
 * @interface FooterApiFooterGetRequest
 */
export interface FooterApiFooterGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof FooterApiFooterGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof FooterApiFooterGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof FooterApiFooterGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof FooterApiFooterGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof FooterApiFooterGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof FooterApiFooterGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof FooterApiFooterGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof FooterApiFooterGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof FooterApiFooterGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof FooterApiFooterGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof FooterApiFooterGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof FooterApiFooterGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for footerPut operation in FooterApi.
 * @export
 * @interface FooterApiFooterPutRequest
 */
export interface FooterApiFooterPutRequest {
    /**
     * 
     * @type {NewFooter}
     * @memberof FooterApiFooterPut
     */
    readonly newFooter: NewFooter
}

/**
 * FooterApi - object-oriented interface
 * @export
 * @class FooterApi
 * @extends {BaseAPI}
 */
export class FooterApi extends BaseAPI {
    /**
     * Delete a single footer record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterApi
     */
    public footerDelete(options?: any) {
        return FooterApiFp(this.configuration).footerDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all the footer\'s records
     * @param {FooterApiFooterGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterApi
     */
    public footerGet(requestParameters: FooterApiFooterGetRequest = {}, options?: any) {
        return FooterApiFp(this.configuration).footerGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a single footer record
     * @param {FooterApiFooterPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterApi
     */
    public footerPut(requestParameters: FooterApiFooterPutRequest, options?: any) {
        return FooterApiFp(this.configuration).footerPut(requestParameters.newFooter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalApi - axios parameter creator
 * @export
 */
export const GlobalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAllDataGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global/allData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a single global record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all the global\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a single global record
         * @param {NewGlobal} newGlobal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPut: async (newGlobal: NewGlobal, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newGlobal' is not null or undefined
            assertParamExists('globalPut', 'newGlobal', newGlobal)
            const localVarPath = `/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newGlobal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalApi - functional programming interface
 * @export
 */
export const GlobalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAllDataGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalAllData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalAllDataGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a single global record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all the global\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Global>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a single global record
         * @param {NewGlobal} newGlobal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalPut(newGlobal: NewGlobal, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Global>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalPut(newGlobal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalApi - factory interface
 * @export
 */
export const GlobalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAllDataGet(options?: any): AxiosPromise<GlobalAllData> {
            return localVarFp.globalAllDataGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a single global record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalDelete(options?: any): AxiosPromise<number> {
            return localVarFp.globalDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Find all the global\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Global> {
            return localVarFp.globalGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a single global record
         * @param {NewGlobal} newGlobal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPut(newGlobal: NewGlobal, options?: any): AxiosPromise<Global> {
            return localVarFp.globalPut(newGlobal, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for globalGet operation in GlobalApi.
 * @export
 * @interface GlobalApiGlobalGetRequest
 */
export interface GlobalApiGlobalGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof GlobalApiGlobalGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof GlobalApiGlobalGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof GlobalApiGlobalGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof GlobalApiGlobalGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof GlobalApiGlobalGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof GlobalApiGlobalGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof GlobalApiGlobalGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof GlobalApiGlobalGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof GlobalApiGlobalGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof GlobalApiGlobalGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof GlobalApiGlobalGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof GlobalApiGlobalGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for globalPut operation in GlobalApi.
 * @export
 * @interface GlobalApiGlobalPutRequest
 */
export interface GlobalApiGlobalPutRequest {
    /**
     * 
     * @type {NewGlobal}
     * @memberof GlobalApiGlobalPut
     */
    readonly newGlobal: NewGlobal
}

/**
 * GlobalApi - object-oriented interface
 * @export
 * @class GlobalApi
 * @extends {BaseAPI}
 */
export class GlobalApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalApi
     */
    public globalAllDataGet(options?: any) {
        return GlobalApiFp(this.configuration).globalAllDataGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a single global record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalApi
     */
    public globalDelete(options?: any) {
        return GlobalApiFp(this.configuration).globalDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all the global\'s records
     * @param {GlobalApiGlobalGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalApi
     */
    public globalGet(requestParameters: GlobalApiGlobalGetRequest = {}, options?: any) {
        return GlobalApiFp(this.configuration).globalGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a single global record
     * @param {GlobalApiGlobalPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalApi
     */
    public globalPut(requestParameters: GlobalApiGlobalPutRequest, options?: any) {
        return GlobalApiFp(this.configuration).globalPut(requestParameters.newGlobal, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HomepageApi - axios parameter creator
 * @export
 */
export const HomepageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepageAllDataGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/homepage/allData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a single homepage record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepageDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/homepage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all the homepage\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepageGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/homepage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a single homepage record
         * @param {NewHomepage} newHomepage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepagePut: async (newHomepage: NewHomepage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newHomepage' is not null or undefined
            assertParamExists('homepagePut', 'newHomepage', newHomepage)
            const localVarPath = `/homepage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newHomepage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HomepageApi - functional programming interface
 * @export
 */
export const HomepageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HomepageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async homepageAllDataGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomepageAllData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.homepageAllDataGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a single homepage record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async homepageDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.homepageDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all the homepage\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async homepageGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Homepage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.homepageGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a single homepage record
         * @param {NewHomepage} newHomepage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async homepagePut(newHomepage: NewHomepage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Homepage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.homepagePut(newHomepage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HomepageApi - factory interface
 * @export
 */
export const HomepageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HomepageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepageAllDataGet(options?: any): AxiosPromise<HomepageAllData> {
            return localVarFp.homepageAllDataGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a single homepage record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepageDelete(options?: any): AxiosPromise<number> {
            return localVarFp.homepageDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Find all the homepage\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepageGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Homepage> {
            return localVarFp.homepageGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a single homepage record
         * @param {NewHomepage} newHomepage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepagePut(newHomepage: NewHomepage, options?: any): AxiosPromise<Homepage> {
            return localVarFp.homepagePut(newHomepage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for homepageGet operation in HomepageApi.
 * @export
 * @interface HomepageApiHomepageGetRequest
 */
export interface HomepageApiHomepageGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof HomepageApiHomepageGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof HomepageApiHomepageGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof HomepageApiHomepageGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof HomepageApiHomepageGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof HomepageApiHomepageGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof HomepageApiHomepageGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof HomepageApiHomepageGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof HomepageApiHomepageGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof HomepageApiHomepageGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof HomepageApiHomepageGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof HomepageApiHomepageGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof HomepageApiHomepageGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for homepagePut operation in HomepageApi.
 * @export
 * @interface HomepageApiHomepagePutRequest
 */
export interface HomepageApiHomepagePutRequest {
    /**
     * 
     * @type {NewHomepage}
     * @memberof HomepageApiHomepagePut
     */
    readonly newHomepage: NewHomepage
}

/**
 * HomepageApi - object-oriented interface
 * @export
 * @class HomepageApi
 * @extends {BaseAPI}
 */
export class HomepageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public homepageAllDataGet(options?: any) {
        return HomepageApiFp(this.configuration).homepageAllDataGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a single homepage record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public homepageDelete(options?: any) {
        return HomepageApiFp(this.configuration).homepageDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all the homepage\'s records
     * @param {HomepageApiHomepageGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public homepageGet(requestParameters: HomepageApiHomepageGetRequest = {}, options?: any) {
        return HomepageApiFp(this.configuration).homepageGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a single homepage record
     * @param {HomepageApiHomepagePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public homepagePut(requestParameters: HomepageApiHomepagePutRequest, options?: any) {
        return HomepageApiFp(this.configuration).homepagePut(requestParameters.newHomepage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LinksApi - axios parameter creator
 * @export
 */
export const LinksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the numver of links documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksCountGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/links/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all the links\'s records
         * @param {Array<string>} [slugs] Links element.
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksGet: async (slugs?: Array<string>, limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (slugs) {
                localVarQueryParameter['slugs'] = slugs;
            }

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a single links record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('linksIdDelete', 'id', id)
            const localVarPath = `/links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a single links record
         * @param {string} id 
         * @param {NewLinks} newLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksIdPut: async (id: string, newLinks: NewLinks, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('linksIdPut', 'id', id)
            // verify required parameter 'newLinks' is not null or undefined
            assertParamExists('linksIdPut', 'newLinks', newLinks)
            const localVarPath = `/links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newLinks, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksPathsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/links/paths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new links record
         * @param {NewLinks} newLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksPost: async (newLinks: NewLinks, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newLinks' is not null or undefined
            assertParamExists('linksPost', 'newLinks', newLinks)
            const localVarPath = `/links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newLinks, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinksApi - functional programming interface
 * @export
 */
export const LinksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinksApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the numver of links documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linksCountGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linksCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all the links\'s records
         * @param {Array<string>} [slugs] Links element.
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linksGet(slugs?: Array<string>, limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Links>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linksGet(slugs, limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a single links record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linksIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linksIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a single links record
         * @param {string} id 
         * @param {NewLinks} newLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linksIdPut(id: string, newLinks: NewLinks, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Links>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linksIdPut(id, newLinks, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linksPathsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Paths>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linksPathsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new links record
         * @param {NewLinks} newLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linksPost(newLinks: NewLinks, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Links>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linksPost(newLinks, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LinksApi - factory interface
 * @export
 */
export const LinksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinksApiFp(configuration)
    return {
        /**
         * Retrieve the numver of links documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksCountGet(options?: any): AxiosPromise<object> {
            return localVarFp.linksCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Find all the links\'s records
         * @param {Array<string>} [slugs] Links element.
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksGet(slugs?: Array<string>, limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<Links>> {
            return localVarFp.linksGet(slugs, limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a single links record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksIdDelete(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.linksIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a single links record
         * @param {string} id 
         * @param {NewLinks} newLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksIdPut(id: string, newLinks: NewLinks, options?: any): AxiosPromise<Links> {
            return localVarFp.linksIdPut(id, newLinks, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksPathsGet(options?: any): AxiosPromise<Paths> {
            return localVarFp.linksPathsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new links record
         * @param {NewLinks} newLinks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksPost(newLinks: NewLinks, options?: any): AxiosPromise<Links> {
            return localVarFp.linksPost(newLinks, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for linksGet operation in LinksApi.
 * @export
 * @interface LinksApiLinksGetRequest
 */
export interface LinksApiLinksGetRequest {
    /**
     * Links element.
     * @type {Array<string>}
     * @memberof LinksApiLinksGet
     */
    readonly slugs?: Array<string>

    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof LinksApiLinksGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof LinksApiLinksGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof LinksApiLinksGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof LinksApiLinksGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof LinksApiLinksGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof LinksApiLinksGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof LinksApiLinksGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof LinksApiLinksGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof LinksApiLinksGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof LinksApiLinksGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof LinksApiLinksGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof LinksApiLinksGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for linksIdDelete operation in LinksApi.
 * @export
 * @interface LinksApiLinksIdDeleteRequest
 */
export interface LinksApiLinksIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof LinksApiLinksIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for linksIdPut operation in LinksApi.
 * @export
 * @interface LinksApiLinksIdPutRequest
 */
export interface LinksApiLinksIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof LinksApiLinksIdPut
     */
    readonly id: string

    /**
     * 
     * @type {NewLinks}
     * @memberof LinksApiLinksIdPut
     */
    readonly newLinks: NewLinks
}

/**
 * Request parameters for linksPost operation in LinksApi.
 * @export
 * @interface LinksApiLinksPostRequest
 */
export interface LinksApiLinksPostRequest {
    /**
     * 
     * @type {NewLinks}
     * @memberof LinksApiLinksPost
     */
    readonly newLinks: NewLinks
}

/**
 * LinksApi - object-oriented interface
 * @export
 * @class LinksApi
 * @extends {BaseAPI}
 */
export class LinksApi extends BaseAPI {
    /**
     * Retrieve the numver of links documents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public linksCountGet(options?: any) {
        return LinksApiFp(this.configuration).linksCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all the links\'s records
     * @param {LinksApiLinksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public linksGet(requestParameters: LinksApiLinksGetRequest = {}, options?: any) {
        return LinksApiFp(this.configuration).linksGet(requestParameters.slugs, requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a single links record
     * @param {LinksApiLinksIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public linksIdDelete(requestParameters: LinksApiLinksIdDeleteRequest, options?: any) {
        return LinksApiFp(this.configuration).linksIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a single links record
     * @param {LinksApiLinksIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public linksIdPut(requestParameters: LinksApiLinksIdPutRequest, options?: any) {
        return LinksApiFp(this.configuration).linksIdPut(requestParameters.id, requestParameters.newLinks, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public linksPathsGet(options?: any) {
        return LinksApiFp(this.configuration).linksPathsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new links record
     * @param {LinksApiLinksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public linksPost(requestParameters: LinksApiLinksPostRequest, options?: any) {
        return LinksApiFp(this.configuration).linksPost(requestParameters.newLinks, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogosPageApi - axios parameter creator
 * @export
 */
export const LogosPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Find all the logos-page\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logosPageGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logos-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogosPageApi - functional programming interface
 * @export
 */
export const LogosPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogosPageApiAxiosParamCreator(configuration)
    return {
        /**
         * Find all the logos-page\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logosPageGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogosPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logosPageGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogosPageApi - factory interface
 * @export
 */
export const LogosPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogosPageApiFp(configuration)
    return {
        /**
         * Find all the logos-page\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logosPageGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<LogosPage> {
            return localVarFp.logosPageGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for logosPageGet operation in LogosPageApi.
 * @export
 * @interface LogosPageApiLogosPageGetRequest
 */
export interface LogosPageApiLogosPageGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof LogosPageApiLogosPageGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof LogosPageApiLogosPageGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof LogosPageApiLogosPageGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof LogosPageApiLogosPageGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof LogosPageApiLogosPageGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof LogosPageApiLogosPageGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof LogosPageApiLogosPageGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof LogosPageApiLogosPageGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof LogosPageApiLogosPageGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof LogosPageApiLogosPageGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof LogosPageApiLogosPageGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof LogosPageApiLogosPageGet
     */
    readonly nin?: Array<string>
}

/**
 * LogosPageApi - object-oriented interface
 * @export
 * @class LogosPageApi
 * @extends {BaseAPI}
 */
export class LogosPageApi extends BaseAPI {
    /**
     * Find all the logos-page\'s records
     * @param {LogosPageApiLogosPageGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogosPageApi
     */
    public logosPageGet(requestParameters: LogosPageApiLogosPageGetRequest = {}, options?: any) {
        return LogosPageApiFp(this.configuration).logosPageGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrApi - axios parameter creator
 * @export
 */
export const PrApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a single pr record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all the pr\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a single pr record
         * @param {NewPr} newPr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prPut: async (newPr: NewPr, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newPr' is not null or undefined
            assertParamExists('prPut', 'newPr', newPr)
            const localVarPath = `/pr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPr, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrApi - functional programming interface
 * @export
 */
export const PrApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a single pr record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all the pr\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pr>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a single pr record
         * @param {NewPr} newPr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prPut(newPr: NewPr, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pr>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prPut(newPr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrApi - factory interface
 * @export
 */
export const PrApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrApiFp(configuration)
    return {
        /**
         * Delete a single pr record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prDelete(options?: any): AxiosPromise<number> {
            return localVarFp.prDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Find all the pr\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Pr> {
            return localVarFp.prGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a single pr record
         * @param {NewPr} newPr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prPut(newPr: NewPr, options?: any): AxiosPromise<Pr> {
            return localVarFp.prPut(newPr, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for prGet operation in PrApi.
 * @export
 * @interface PrApiPrGetRequest
 */
export interface PrApiPrGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof PrApiPrGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof PrApiPrGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof PrApiPrGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof PrApiPrGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof PrApiPrGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof PrApiPrGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof PrApiPrGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof PrApiPrGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof PrApiPrGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof PrApiPrGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof PrApiPrGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof PrApiPrGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for prPut operation in PrApi.
 * @export
 * @interface PrApiPrPutRequest
 */
export interface PrApiPrPutRequest {
    /**
     * 
     * @type {NewPr}
     * @memberof PrApiPrPut
     */
    readonly newPr: NewPr
}

/**
 * PrApi - object-oriented interface
 * @export
 * @class PrApi
 * @extends {BaseAPI}
 */
export class PrApi extends BaseAPI {
    /**
     * Delete a single pr record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrApi
     */
    public prDelete(options?: any) {
        return PrApiFp(this.configuration).prDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all the pr\'s records
     * @param {PrApiPrGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrApi
     */
    public prGet(requestParameters: PrApiPrGetRequest = {}, options?: any) {
        return PrApiFp(this.configuration).prGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a single pr record
     * @param {PrApiPrPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrApi
     */
    public prPut(requestParameters: PrApiPrPutRequest, options?: any) {
        return PrApiFp(this.configuration).prPut(requestParameters.newPr, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrivacyPolicyApi - axios parameter creator
 * @export
 */
export const PrivacyPolicyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a single privacy-policy record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyPolicyDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/privacy-policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all the privacy-policy\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyPolicyGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/privacy-policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a single privacy-policy record
         * @param {NewPrivacyPolicy} newPrivacyPolicy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyPolicyPut: async (newPrivacyPolicy: NewPrivacyPolicy, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newPrivacyPolicy' is not null or undefined
            assertParamExists('privacyPolicyPut', 'newPrivacyPolicy', newPrivacyPolicy)
            const localVarPath = `/privacy-policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPrivacyPolicy, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivacyPolicyApi - functional programming interface
 * @export
 */
export const PrivacyPolicyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivacyPolicyApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a single privacy-policy record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privacyPolicyDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privacyPolicyDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all the privacy-policy\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privacyPolicyGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivacyPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privacyPolicyGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a single privacy-policy record
         * @param {NewPrivacyPolicy} newPrivacyPolicy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privacyPolicyPut(newPrivacyPolicy: NewPrivacyPolicy, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privacyPolicyPut(newPrivacyPolicy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivacyPolicyApi - factory interface
 * @export
 */
export const PrivacyPolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivacyPolicyApiFp(configuration)
    return {
        /**
         * Delete a single privacy-policy record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyPolicyDelete(options?: any): AxiosPromise<number> {
            return localVarFp.privacyPolicyDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Find all the privacy-policy\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyPolicyGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<PrivacyPolicy> {
            return localVarFp.privacyPolicyGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a single privacy-policy record
         * @param {NewPrivacyPolicy} newPrivacyPolicy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyPolicyPut(newPrivacyPolicy: NewPrivacyPolicy, options?: any): AxiosPromise<object> {
            return localVarFp.privacyPolicyPut(newPrivacyPolicy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for privacyPolicyGet operation in PrivacyPolicyApi.
 * @export
 * @interface PrivacyPolicyApiPrivacyPolicyGetRequest
 */
export interface PrivacyPolicyApiPrivacyPolicyGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof PrivacyPolicyApiPrivacyPolicyGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof PrivacyPolicyApiPrivacyPolicyGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof PrivacyPolicyApiPrivacyPolicyGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof PrivacyPolicyApiPrivacyPolicyGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof PrivacyPolicyApiPrivacyPolicyGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof PrivacyPolicyApiPrivacyPolicyGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof PrivacyPolicyApiPrivacyPolicyGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof PrivacyPolicyApiPrivacyPolicyGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof PrivacyPolicyApiPrivacyPolicyGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof PrivacyPolicyApiPrivacyPolicyGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof PrivacyPolicyApiPrivacyPolicyGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof PrivacyPolicyApiPrivacyPolicyGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for privacyPolicyPut operation in PrivacyPolicyApi.
 * @export
 * @interface PrivacyPolicyApiPrivacyPolicyPutRequest
 */
export interface PrivacyPolicyApiPrivacyPolicyPutRequest {
    /**
     * 
     * @type {NewPrivacyPolicy}
     * @memberof PrivacyPolicyApiPrivacyPolicyPut
     */
    readonly newPrivacyPolicy: NewPrivacyPolicy
}

/**
 * PrivacyPolicyApi - object-oriented interface
 * @export
 * @class PrivacyPolicyApi
 * @extends {BaseAPI}
 */
export class PrivacyPolicyApi extends BaseAPI {
    /**
     * Delete a single privacy-policy record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivacyPolicyApi
     */
    public privacyPolicyDelete(options?: any) {
        return PrivacyPolicyApiFp(this.configuration).privacyPolicyDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all the privacy-policy\'s records
     * @param {PrivacyPolicyApiPrivacyPolicyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivacyPolicyApi
     */
    public privacyPolicyGet(requestParameters: PrivacyPolicyApiPrivacyPolicyGetRequest = {}, options?: any) {
        return PrivacyPolicyApiFp(this.configuration).privacyPolicyGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a single privacy-policy record
     * @param {PrivacyPolicyApiPrivacyPolicyPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivacyPolicyApi
     */
    public privacyPolicyPut(requestParameters: PrivacyPolicyApiPrivacyPolicyPutRequest, options?: any) {
        return PrivacyPolicyApiFp(this.configuration).privacyPolicyPut(requestParameters.newPrivacyPolicy, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProxyApi - axios parameter creator
 * @export
 */
export const ProxyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new record
         * @param {ApiCallPayload} apiCallPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyExternalApiPost: async (apiCallPayload: ApiCallPayload, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiCallPayload' is not null or undefined
            assertParamExists('proxyExternalApiPost', 'apiCallPayload', apiCallPayload)
            const localVarPath = `/proxy/external-api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiCallPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProxyApi - functional programming interface
 * @export
 */
export const ProxyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProxyApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new record
         * @param {ApiCallPayload} apiCallPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyExternalApiPost(apiCallPayload: ApiCallPayload, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCallResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxyExternalApiPost(apiCallPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProxyApi - factory interface
 * @export
 */
export const ProxyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProxyApiFp(configuration)
    return {
        /**
         * Create a new record
         * @param {ApiCallPayload} apiCallPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyExternalApiPost(apiCallPayload: ApiCallPayload, options?: any): AxiosPromise<ApiCallResult> {
            return localVarFp.proxyExternalApiPost(apiCallPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for proxyExternalApiPost operation in ProxyApi.
 * @export
 * @interface ProxyApiProxyExternalApiPostRequest
 */
export interface ProxyApiProxyExternalApiPostRequest {
    /**
     * 
     * @type {ApiCallPayload}
     * @memberof ProxyApiProxyExternalApiPost
     */
    readonly apiCallPayload: ApiCallPayload
}

/**
 * ProxyApi - object-oriented interface
 * @export
 * @class ProxyApi
 * @extends {BaseAPI}
 */
export class ProxyApi extends BaseAPI {
    /**
     * Create a new record
     * @param {ProxyApiProxyExternalApiPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProxyApi
     */
    public proxyExternalApiPost(requestParameters: ProxyApiProxyExternalApiPostRequest, options?: any) {
        return ProxyApiFp(this.configuration).proxyExternalApiPost(requestParameters.apiCallPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFiltersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/search/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all the search\'s records
         * @param {string} q Search query.
         * @param {object} [pages] Number of pages per search index.
         * @param {object} [filters] Filters used to filter out search results based od facets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGet: async (q: string, pages?: object, filters?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('searchGet', 'q', q)
            const localVarPath = `/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['_q'] = q;
            }

            if (pages !== undefined) {
                localVarQueryParameter['_pages'] = pages;
            }

            if (filters !== undefined) {
                localVarQueryParameter['_filters'] = filters;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFiltersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchFilters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFiltersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all the search\'s records
         * @param {string} q Search query.
         * @param {object} [pages] Number of pages per search index.
         * @param {object} [filters] Filters used to filter out search results based od facets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchGet(q: string, pages?: object, filters?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Search>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchGet(q, pages, filters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFiltersGet(options?: any): AxiosPromise<SearchFilters> {
            return localVarFp.searchFiltersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Find all the search\'s records
         * @param {string} q Search query.
         * @param {object} [pages] Number of pages per search index.
         * @param {object} [filters] Filters used to filter out search results based od facets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGet(q: string, pages?: object, filters?: object, options?: any): AxiosPromise<Search> {
            return localVarFp.searchGet(q, pages, filters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for searchGet operation in SearchApi.
 * @export
 * @interface SearchApiSearchGetRequest
 */
export interface SearchApiSearchGetRequest {
    /**
     * Search query.
     * @type {string}
     * @memberof SearchApiSearchGet
     */
    readonly q: string

    /**
     * Number of pages per search index.
     * @type {object}
     * @memberof SearchApiSearchGet
     */
    readonly pages?: object

    /**
     * Filters used to filter out search results based od facets.
     * @type {object}
     * @memberof SearchApiSearchGet
     */
    readonly filters?: object
}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchFiltersGet(options?: any) {
        return SearchApiFp(this.configuration).searchFiltersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all the search\'s records
     * @param {SearchApiSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchGet(requestParameters: SearchApiSearchGetRequest, options?: any) {
        return SearchApiFp(this.configuration).searchGet(requestParameters.q, requestParameters.pages, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubcategoryApi - axios parameter creator
 * @export
 */
export const SubcategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesCountGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subcategories/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subcategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subcategoriesIdDelete', 'id', id)
            const localVarPath = `/subcategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subcategoriesIdGet', 'id', id)
            const localVarPath = `/subcategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewSubcategory} newSubcategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesIdPut: async (id: string, newSubcategory: NewSubcategory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subcategoriesIdPut', 'id', id)
            // verify required parameter 'newSubcategory' is not null or undefined
            assertParamExists('subcategoriesIdPut', 'newSubcategory', newSubcategory)
            const localVarPath = `/subcategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newSubcategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new record
         * @param {NewSubcategory} newSubcategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesPost: async (newSubcategory: NewSubcategory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newSubcategory' is not null or undefined
            assertParamExists('subcategoriesPost', 'newSubcategory', newSubcategory)
            const localVarPath = `/subcategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newSubcategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubcategoryApi - functional programming interface
 * @export
 */
export const SubcategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubcategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoriesCountGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subcategoriesCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoriesGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Subcategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subcategoriesGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoriesIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subcategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoriesIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subcategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subcategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewSubcategory} newSubcategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoriesIdPut(id: string, newSubcategory: NewSubcategory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subcategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subcategoriesIdPut(id, newSubcategory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new record
         * @param {NewSubcategory} newSubcategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoriesPost(newSubcategory: NewSubcategory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subcategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subcategoriesPost(newSubcategory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubcategoryApi - factory interface
 * @export
 */
export const SubcategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubcategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesCountGet(options?: any): AxiosPromise<object> {
            return localVarFp.subcategoriesCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<Subcategory>> {
            return localVarFp.subcategoriesGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesIdDelete(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.subcategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesIdGet(id: string, options?: any): AxiosPromise<Subcategory> {
            return localVarFp.subcategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewSubcategory} newSubcategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesIdPut(id: string, newSubcategory: NewSubcategory, options?: any): AxiosPromise<Subcategory> {
            return localVarFp.subcategoriesIdPut(id, newSubcategory, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new record
         * @param {NewSubcategory} newSubcategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesPost(newSubcategory: NewSubcategory, options?: any): AxiosPromise<Subcategory> {
            return localVarFp.subcategoriesPost(newSubcategory, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for subcategoriesGet operation in SubcategoryApi.
 * @export
 * @interface SubcategoryApiSubcategoriesGetRequest
 */
export interface SubcategoryApiSubcategoriesGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof SubcategoryApiSubcategoriesGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof SubcategoryApiSubcategoriesGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof SubcategoryApiSubcategoriesGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof SubcategoryApiSubcategoriesGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof SubcategoryApiSubcategoriesGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof SubcategoryApiSubcategoriesGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof SubcategoryApiSubcategoriesGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof SubcategoryApiSubcategoriesGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof SubcategoryApiSubcategoriesGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof SubcategoryApiSubcategoriesGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof SubcategoryApiSubcategoriesGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof SubcategoryApiSubcategoriesGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for subcategoriesIdDelete operation in SubcategoryApi.
 * @export
 * @interface SubcategoryApiSubcategoriesIdDeleteRequest
 */
export interface SubcategoryApiSubcategoriesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof SubcategoryApiSubcategoriesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for subcategoriesIdGet operation in SubcategoryApi.
 * @export
 * @interface SubcategoryApiSubcategoriesIdGetRequest
 */
export interface SubcategoryApiSubcategoriesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof SubcategoryApiSubcategoriesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for subcategoriesIdPut operation in SubcategoryApi.
 * @export
 * @interface SubcategoryApiSubcategoriesIdPutRequest
 */
export interface SubcategoryApiSubcategoriesIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof SubcategoryApiSubcategoriesIdPut
     */
    readonly id: string

    /**
     * 
     * @type {NewSubcategory}
     * @memberof SubcategoryApiSubcategoriesIdPut
     */
    readonly newSubcategory: NewSubcategory
}

/**
 * Request parameters for subcategoriesPost operation in SubcategoryApi.
 * @export
 * @interface SubcategoryApiSubcategoriesPostRequest
 */
export interface SubcategoryApiSubcategoriesPostRequest {
    /**
     * 
     * @type {NewSubcategory}
     * @memberof SubcategoryApiSubcategoriesPost
     */
    readonly newSubcategory: NewSubcategory
}

/**
 * SubcategoryApi - object-oriented interface
 * @export
 * @class SubcategoryApi
 * @extends {BaseAPI}
 */
export class SubcategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoryApi
     */
    public subcategoriesCountGet(options?: any) {
        return SubcategoryApiFp(this.configuration).subcategoriesCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubcategoryApiSubcategoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoryApi
     */
    public subcategoriesGet(requestParameters: SubcategoryApiSubcategoriesGetRequest = {}, options?: any) {
        return SubcategoryApiFp(this.configuration).subcategoriesGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a record
     * @param {SubcategoryApiSubcategoriesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoryApi
     */
    public subcategoriesIdDelete(requestParameters: SubcategoryApiSubcategoriesIdDeleteRequest, options?: any) {
        return SubcategoryApiFp(this.configuration).subcategoriesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubcategoryApiSubcategoriesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoryApi
     */
    public subcategoriesIdGet(requestParameters: SubcategoryApiSubcategoriesIdGetRequest, options?: any) {
        return SubcategoryApiFp(this.configuration).subcategoriesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a record
     * @param {SubcategoryApiSubcategoriesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoryApi
     */
    public subcategoriesIdPut(requestParameters: SubcategoryApiSubcategoriesIdPutRequest, options?: any) {
        return SubcategoryApiFp(this.configuration).subcategoriesIdPut(requestParameters.id, requestParameters.newSubcategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new record
     * @param {SubcategoryApiSubcategoriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoryApi
     */
    public subcategoriesPost(requestParameters: SubcategoryApiSubcategoriesPostRequest, options?: any) {
        return SubcategoryApiFp(this.configuration).subcategoriesPost(requestParameters.newSubcategory, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsCountGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tags/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tagsIdDelete', 'id', id)
            const localVarPath = `/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tagsIdGet', 'id', id)
            const localVarPath = `/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewTag} newTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsIdPut: async (id: string, newTag: NewTag, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tagsIdPut', 'id', id)
            // verify required parameter 'newTag' is not null or undefined
            assertParamExists('tagsIdPut', 'newTag', newTag)
            const localVarPath = `/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newTag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new record
         * @param {NewTag} newTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsPost: async (newTag: NewTag, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newTag' is not null or undefined
            assertParamExists('tagsPost', 'newTag', newTag)
            const localVarPath = `/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newTag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsCountGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewTag} newTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsIdPut(id: string, newTag: NewTag, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsIdPut(id, newTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new record
         * @param {NewTag} newTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsPost(newTag: NewTag, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsPost(newTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsCountGet(options?: any): AxiosPromise<object> {
            return localVarFp.tagsCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<Tag>> {
            return localVarFp.tagsGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsIdDelete(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.tagsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsIdGet(id: string, options?: any): AxiosPromise<Tag> {
            return localVarFp.tagsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewTag} newTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsIdPut(id: string, newTag: NewTag, options?: any): AxiosPromise<Tag> {
            return localVarFp.tagsIdPut(id, newTag, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new record
         * @param {NewTag} newTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsPost(newTag: NewTag, options?: any): AxiosPromise<Tag> {
            return localVarFp.tagsPost(newTag, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for tagsGet operation in TagApi.
 * @export
 * @interface TagApiTagsGetRequest
 */
export interface TagApiTagsGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof TagApiTagsGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof TagApiTagsGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof TagApiTagsGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof TagApiTagsGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof TagApiTagsGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof TagApiTagsGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof TagApiTagsGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof TagApiTagsGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof TagApiTagsGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof TagApiTagsGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof TagApiTagsGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof TagApiTagsGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for tagsIdDelete operation in TagApi.
 * @export
 * @interface TagApiTagsIdDeleteRequest
 */
export interface TagApiTagsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof TagApiTagsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for tagsIdGet operation in TagApi.
 * @export
 * @interface TagApiTagsIdGetRequest
 */
export interface TagApiTagsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TagApiTagsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for tagsIdPut operation in TagApi.
 * @export
 * @interface TagApiTagsIdPutRequest
 */
export interface TagApiTagsIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof TagApiTagsIdPut
     */
    readonly id: string

    /**
     * 
     * @type {NewTag}
     * @memberof TagApiTagsIdPut
     */
    readonly newTag: NewTag
}

/**
 * Request parameters for tagsPost operation in TagApi.
 * @export
 * @interface TagApiTagsPostRequest
 */
export interface TagApiTagsPostRequest {
    /**
     * 
     * @type {NewTag}
     * @memberof TagApiTagsPost
     */
    readonly newTag: NewTag
}

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public tagsCountGet(options?: any) {
        return TagApiFp(this.configuration).tagsCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagApiTagsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public tagsGet(requestParameters: TagApiTagsGetRequest = {}, options?: any) {
        return TagApiFp(this.configuration).tagsGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a record
     * @param {TagApiTagsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public tagsIdDelete(requestParameters: TagApiTagsIdDeleteRequest, options?: any) {
        return TagApiFp(this.configuration).tagsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagApiTagsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public tagsIdGet(requestParameters: TagApiTagsIdGetRequest, options?: any) {
        return TagApiFp(this.configuration).tagsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a record
     * @param {TagApiTagsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public tagsIdPut(requestParameters: TagApiTagsIdPutRequest, options?: any) {
        return TagApiFp(this.configuration).tagsIdPut(requestParameters.id, requestParameters.newTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new record
     * @param {TagApiTagsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public tagsPost(requestParameters: TagApiTagsPostRequest, options?: any) {
        return TagApiFp(this.configuration).tagsPost(requestParameters.newTag, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TermsOfUseApi - axios parameter creator
 * @export
 */
export const TermsOfUseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a single terms-of-use record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsOfUseDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/terms-of-use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all the terms-of-use\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsOfUseGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/terms-of-use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a single terms-of-use record
         * @param {NewTermsOfUse} newTermsOfUse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsOfUsePut: async (newTermsOfUse: NewTermsOfUse, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newTermsOfUse' is not null or undefined
            assertParamExists('termsOfUsePut', 'newTermsOfUse', newTermsOfUse)
            const localVarPath = `/terms-of-use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newTermsOfUse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TermsOfUseApi - functional programming interface
 * @export
 */
export const TermsOfUseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TermsOfUseApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a single terms-of-use record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async termsOfUseDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.termsOfUseDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all the terms-of-use\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async termsOfUseGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermsOfUse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.termsOfUseGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a single terms-of-use record
         * @param {NewTermsOfUse} newTermsOfUse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async termsOfUsePut(newTermsOfUse: NewTermsOfUse, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.termsOfUsePut(newTermsOfUse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TermsOfUseApi - factory interface
 * @export
 */
export const TermsOfUseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TermsOfUseApiFp(configuration)
    return {
        /**
         * Delete a single terms-of-use record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsOfUseDelete(options?: any): AxiosPromise<number> {
            return localVarFp.termsOfUseDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Find all the terms-of-use\'s records
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsOfUseGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<TermsOfUse> {
            return localVarFp.termsOfUseGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a single terms-of-use record
         * @param {NewTermsOfUse} newTermsOfUse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsOfUsePut(newTermsOfUse: NewTermsOfUse, options?: any): AxiosPromise<object> {
            return localVarFp.termsOfUsePut(newTermsOfUse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for termsOfUseGet operation in TermsOfUseApi.
 * @export
 * @interface TermsOfUseApiTermsOfUseGetRequest
 */
export interface TermsOfUseApiTermsOfUseGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof TermsOfUseApiTermsOfUseGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof TermsOfUseApiTermsOfUseGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof TermsOfUseApiTermsOfUseGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof TermsOfUseApiTermsOfUseGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof TermsOfUseApiTermsOfUseGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof TermsOfUseApiTermsOfUseGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof TermsOfUseApiTermsOfUseGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof TermsOfUseApiTermsOfUseGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof TermsOfUseApiTermsOfUseGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof TermsOfUseApiTermsOfUseGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof TermsOfUseApiTermsOfUseGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof TermsOfUseApiTermsOfUseGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for termsOfUsePut operation in TermsOfUseApi.
 * @export
 * @interface TermsOfUseApiTermsOfUsePutRequest
 */
export interface TermsOfUseApiTermsOfUsePutRequest {
    /**
     * 
     * @type {NewTermsOfUse}
     * @memberof TermsOfUseApiTermsOfUsePut
     */
    readonly newTermsOfUse: NewTermsOfUse
}

/**
 * TermsOfUseApi - object-oriented interface
 * @export
 * @class TermsOfUseApi
 * @extends {BaseAPI}
 */
export class TermsOfUseApi extends BaseAPI {
    /**
     * Delete a single terms-of-use record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsOfUseApi
     */
    public termsOfUseDelete(options?: any) {
        return TermsOfUseApiFp(this.configuration).termsOfUseDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all the terms-of-use\'s records
     * @param {TermsOfUseApiTermsOfUseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsOfUseApi
     */
    public termsOfUseGet(requestParameters: TermsOfUseApiTermsOfUseGetRequest = {}, options?: any) {
        return TermsOfUseApiFp(this.configuration).termsOfUseGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a single terms-of-use record
     * @param {TermsOfUseApiTermsOfUsePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsOfUseApi
     */
    public termsOfUsePut(requestParameters: TermsOfUseApiTermsOfUsePutRequest, options?: any) {
        return TermsOfUseApiFp(this.configuration).termsOfUsePut(requestParameters.newTermsOfUse, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WriterApi - axios parameter creator
 * @export
 */
export const WriterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writersCountGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/writers/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writersGet: async (limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/writers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['_limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (ne !== undefined) {
                localVarQueryParameter['_ne'] = ne;
            }

            if (lt !== undefined) {
                localVarQueryParameter['_lt'] = lt;
            }

            if (lte !== undefined) {
                localVarQueryParameter['_lte'] = lte;
            }

            if (gt !== undefined) {
                localVarQueryParameter['_gt'] = gt;
            }

            if (gte !== undefined) {
                localVarQueryParameter['_gte'] = gte;
            }

            if (contains !== undefined) {
                localVarQueryParameter['_contains'] = contains;
            }

            if (containss !== undefined) {
                localVarQueryParameter['_containss'] = containss;
            }

            if (_in) {
                localVarQueryParameter['_in'] = _in;
            }

            if (nin) {
                localVarQueryParameter['_nin'] = nin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writersIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('writersIdDelete', 'id', id)
            const localVarPath = `/writers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writersIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('writersIdGet', 'id', id)
            const localVarPath = `/writers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewWriter} newWriter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writersIdPut: async (id: string, newWriter: NewWriter, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('writersIdPut', 'id', id)
            // verify required parameter 'newWriter' is not null or undefined
            assertParamExists('writersIdPut', 'newWriter', newWriter)
            const localVarPath = `/writers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newWriter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new record
         * @param {NewWriter} newWriter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writersPost: async (newWriter: NewWriter, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newWriter' is not null or undefined
            assertParamExists('writersPost', 'newWriter', newWriter)
            const localVarPath = `/writers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newWriter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WriterApi - functional programming interface
 * @export
 */
export const WriterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WriterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writersCountGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writersCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writersGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Writer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writersGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writersIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writersIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Writer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewWriter} newWriter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writersIdPut(id: string, newWriter: NewWriter, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Writer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writersIdPut(id, newWriter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new record
         * @param {NewWriter} newWriter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writersPost(newWriter: NewWriter, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Writer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writersPost(newWriter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WriterApi - factory interface
 * @export
 */
export const WriterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WriterApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writersCountGet(options?: any): AxiosPromise<object> {
            return localVarFp.writersCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Maximum number of results possible
         * @param {string} [sort] Sort according to a specific field.
         * @param {number} [start] Skip a specific number of entries (especially useful for pagination)
         * @param {string} [ne] Get records that are not equals to something
         * @param {string} [lt] Get record that are lower than a value
         * @param {string} [lte] Get records that are lower than or equal to a value
         * @param {string} [gt] Get records that are greater than a value
         * @param {string} [gte] Get records that are greater than  or equal a value
         * @param {string} [contains] Get records that contains a value
         * @param {string} [containss] Get records that contains (case sensitive) a value
         * @param {Array<string>} [_in] Get records that matches any value in the array of values
         * @param {Array<string>} [nin] Get records that doesn\&#39;t match any value in the array of values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writersGet(limit?: number, sort?: string, start?: number, ne?: string, lt?: string, lte?: string, gt?: string, gte?: string, contains?: string, containss?: string, _in?: Array<string>, nin?: Array<string>, options?: any): AxiosPromise<Array<Writer>> {
            return localVarFp.writersGet(limit, sort, start, ne, lt, lte, gt, gte, contains, containss, _in, nin, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a record
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writersIdDelete(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.writersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writersIdGet(id: string, options?: any): AxiosPromise<Writer> {
            return localVarFp.writersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a record
         * @param {string} id 
         * @param {NewWriter} newWriter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writersIdPut(id: string, newWriter: NewWriter, options?: any): AxiosPromise<Writer> {
            return localVarFp.writersIdPut(id, newWriter, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new record
         * @param {NewWriter} newWriter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writersPost(newWriter: NewWriter, options?: any): AxiosPromise<Writer> {
            return localVarFp.writersPost(newWriter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for writersGet operation in WriterApi.
 * @export
 * @interface WriterApiWritersGetRequest
 */
export interface WriterApiWritersGetRequest {
    /**
     * Maximum number of results possible
     * @type {number}
     * @memberof WriterApiWritersGet
     */
    readonly limit?: number

    /**
     * Sort according to a specific field.
     * @type {string}
     * @memberof WriterApiWritersGet
     */
    readonly sort?: string

    /**
     * Skip a specific number of entries (especially useful for pagination)
     * @type {number}
     * @memberof WriterApiWritersGet
     */
    readonly start?: number

    /**
     * Get records that are not equals to something
     * @type {string}
     * @memberof WriterApiWritersGet
     */
    readonly ne?: string

    /**
     * Get record that are lower than a value
     * @type {string}
     * @memberof WriterApiWritersGet
     */
    readonly lt?: string

    /**
     * Get records that are lower than or equal to a value
     * @type {string}
     * @memberof WriterApiWritersGet
     */
    readonly lte?: string

    /**
     * Get records that are greater than a value
     * @type {string}
     * @memberof WriterApiWritersGet
     */
    readonly gt?: string

    /**
     * Get records that are greater than  or equal a value
     * @type {string}
     * @memberof WriterApiWritersGet
     */
    readonly gte?: string

    /**
     * Get records that contains a value
     * @type {string}
     * @memberof WriterApiWritersGet
     */
    readonly contains?: string

    /**
     * Get records that contains (case sensitive) a value
     * @type {string}
     * @memberof WriterApiWritersGet
     */
    readonly containss?: string

    /**
     * Get records that matches any value in the array of values
     * @type {Array<string>}
     * @memberof WriterApiWritersGet
     */
    readonly _in?: Array<string>

    /**
     * Get records that doesn\&#39;t match any value in the array of values
     * @type {Array<string>}
     * @memberof WriterApiWritersGet
     */
    readonly nin?: Array<string>
}

/**
 * Request parameters for writersIdDelete operation in WriterApi.
 * @export
 * @interface WriterApiWritersIdDeleteRequest
 */
export interface WriterApiWritersIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof WriterApiWritersIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for writersIdGet operation in WriterApi.
 * @export
 * @interface WriterApiWritersIdGetRequest
 */
export interface WriterApiWritersIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof WriterApiWritersIdGet
     */
    readonly id: string
}

/**
 * Request parameters for writersIdPut operation in WriterApi.
 * @export
 * @interface WriterApiWritersIdPutRequest
 */
export interface WriterApiWritersIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof WriterApiWritersIdPut
     */
    readonly id: string

    /**
     * 
     * @type {NewWriter}
     * @memberof WriterApiWritersIdPut
     */
    readonly newWriter: NewWriter
}

/**
 * Request parameters for writersPost operation in WriterApi.
 * @export
 * @interface WriterApiWritersPostRequest
 */
export interface WriterApiWritersPostRequest {
    /**
     * 
     * @type {NewWriter}
     * @memberof WriterApiWritersPost
     */
    readonly newWriter: NewWriter
}

/**
 * WriterApi - object-oriented interface
 * @export
 * @class WriterApi
 * @extends {BaseAPI}
 */
export class WriterApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WriterApi
     */
    public writersCountGet(options?: any) {
        return WriterApiFp(this.configuration).writersCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WriterApiWritersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WriterApi
     */
    public writersGet(requestParameters: WriterApiWritersGetRequest = {}, options?: any) {
        return WriterApiFp(this.configuration).writersGet(requestParameters.limit, requestParameters.sort, requestParameters.start, requestParameters.ne, requestParameters.lt, requestParameters.lte, requestParameters.gt, requestParameters.gte, requestParameters.contains, requestParameters.containss, requestParameters._in, requestParameters.nin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a record
     * @param {WriterApiWritersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WriterApi
     */
    public writersIdDelete(requestParameters: WriterApiWritersIdDeleteRequest, options?: any) {
        return WriterApiFp(this.configuration).writersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WriterApiWritersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WriterApi
     */
    public writersIdGet(requestParameters: WriterApiWritersIdGetRequest, options?: any) {
        return WriterApiFp(this.configuration).writersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a record
     * @param {WriterApiWritersIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WriterApi
     */
    public writersIdPut(requestParameters: WriterApiWritersIdPutRequest, options?: any) {
        return WriterApiFp(this.configuration).writersIdPut(requestParameters.id, requestParameters.newWriter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new record
     * @param {WriterApiWritersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WriterApi
     */
    public writersPost(requestParameters: WriterApiWritersPostRequest, options?: any) {
        return WriterApiFp(this.configuration).writersPost(requestParameters.newWriter, options).then((request) => request(this.axios, this.basePath));
    }
}


